import React from 'react';
import UserOnlineStatusContainer from '../ui/UserOnlineStatusContainer';
import ActionsContainer from "../ui/ActionsContainer"
import DiscussionRecommendationsContainer from '../ui/DiscussionRecommendationsContainer';
import CollaborativeStage from './CollaborativeStage';

export default function DiscussionLeaderBoard({data, ws}){





    return (
        <div className="flex flex-col h-full justify-center items-center w-full">
            {data?.stage === 0 && (
                <div className="flex flex-col items-center justify-center w-full max-w-[1000px]">
                    {data?.stage_name && (
                        <div className="flex flex-row items-center justify-center w-full">
                            <h1 className="text-3xl">{data.stage_name}</h1>
                        </div>
                    )}

                    
                    {data?.stage_description && (
                        <div className="flex flex-row items-center justify-center w-full mt-3">
                            <p>{data.stage_description}</p>
                        </div>
                    )}

                    <div className="flex flex-row items-start justify-center w-full mt-12  ">
                        <UserOnlineStatusContainer data={data.users} asBox={true} />
                    </div>

                    {data?.actions && (
                       <div className="w-full">
                            <ActionsContainer actions={data.actions} ws={ws}/> 
                        </div>
                        
                    )}

                </div>

            )}

            {data?.stage > 0 && data?.stage < 4 && (
                <div className="flex flex-col items-center justify-start">
                    <div className="flex flex-row items-start justify-end w-full mt-4 ">
                        <UserOnlineStatusContainer data={data.users} asBox={false} />
                    </div>
                    <hr className="w-full border-1 border-gray-300 mb-4" />
                    {data?.stage_name && (
                        <div className="flex flex-row items-center justify-center w-full mt-6">
                            <h1 className="text-3xl">{data.stage_name}</h1>
                        </div>
                    )}
                

                    <div className="flex flex-row mt-12">
                        {data?.stage_description && (
                            <div className="w-1/2 flex flex-col items-center  w-full h-full">
                                <p className="text-2xl mb-8" >Scenario Description: </p>
                                {data?.stage_description && (
                                    
                                    <p className="text-xl w-3/4 text-center">{data.stage_description}</p>
                                )}
                            </div>
                        )}
                        <div className="border-l border-1 border-gray-300 h-full"></div>
                        {data?.discussion_recommendations && data.discussion_recommendations.length > 0 && (
                            <div className="w-1/2 flex flex-col items-center justify-center w-full h-full">
                            <DiscussionRecommendationsContainer data={data} />
                            </div>
                        )}
                    </div>
                    <hr className="w-full border-1 border-gray-300 mt-12  mb-4" />

                    {data?.actions && (
                        <div className="w-full">
                            <ActionsContainer actions={data.actions} ws={ws}/> 
                        </div>
                        
                    )}
                </div>
            )}

            { data?.stage === 4 && (
                <div className="flex flex-col w-full h-full justify-center items-center mb-4">
                    <div className="w-1/2 flex flex-col items-center  w-full">
                        {data?.stage_description && (
                            <p className="text-lg w-3/4 text-center">{data.stage_description}</p>
                        )}
                    </div>
                    <CollaborativeStage data={data} ws={ws} />
                    {data?.actions && (
                        <div className="w-full">
                            <ActionsContainer actions={data.actions} ws={ws}/> 
                        </div>
                        
                    )}

                </div>
            )}





        </div>

    )
}