import { useState, useEffect } from 'react';
import axiosInstance from '../apiCalls/axios-auth';

const useCheckAsyncRequest = () => {
  const [taskStatus, setTaskStatus] = useState(null);

  const checkAsyncRequest = (task_id) => {
    return new Promise((resolve, reject) => {
      console.log('Task ID:', task_id);
      if (task_id) {
        const intervalId = setInterval(async () => {
          try {
            const response = await axiosInstance.get(`shared-tasks/status/?task_id=${task_id}`);
            console.log(response.data)
            if (response.data.task_status === 'SUCCESS') {
              setTaskStatus('SUCCESS');
              clearInterval(intervalId);
              resolve();
            }
          } catch (error) {
            console.error('Error getting task status:', error);
            return error;
          }
        }, 2000);

        // Clear interval when component unmounts
        return () => clearInterval(intervalId);
      } else {
        reject(new Error('Task ID is not provided'));
      }
    });
  };

  return { taskStatus, checkAsyncRequest };
};

export default useCheckAsyncRequest;