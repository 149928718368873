import { Typography, Box, Card, CardMedia } from '@mui/material';
import AdviceComponent from './AdviceComponent';

const ImageComponent = ({ item, onChangeAdvice, id }) => {
  // console.log(images)
  const { content } = item;
  const { subtitle, data } = content;

    return(
      <Box sx={{ margin: 1, display: "flex", flexDirection: "column", width: "100%" }}>
        <Typography variant="subtitle2" gutterBottom>
          {subtitle}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: "space-evenly" }}>
          {data.map((image, index) => (
            
            <Box key={index} sx={{display: "flex", flexDirection:"column", alignItems: "center", alignContent: "center", bgcolor: "" }}>
              <CardMedia
                component="img"
                sx={{ objectFit: "contain", maxHeight: "120px", maxWidth: "200px" }}
                image={image.imageURL}
                alt={`Image ${index + 1}`}
                onError={(e) => { e.target.src = null; }}
              />
              <Typography sx={{ padding: 1}} >{image.label}</Typography>
            </Box>
            
          ))}
        </Box>
        {content.advices && <AdviceComponent advices={content.advices} onChangeAdvice={onChangeAdvice} id={id} />}

      </Box>
    )
}

export default ImageComponent;