import { Box, Card, CardContent, TextField, Typography } from "@mui/material";

function TextType({ question }) {
  return (
<Box sx={{display:"flex", alignContent:"center"}}>
    <Card sx={{ maxWidth: 800, margin: 'auto', px: 4, py:1, }}>
      <CardContent>
        <Typography variant="h5" component="div">
          {question.title}
        </Typography>
      </CardContent>
      <CardContent>
      <Typography variant="body1">{question.text}</Typography>
    </CardContent>
    </Card>
    </Box>
  );
}

export default TextType;