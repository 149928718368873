


export default function CategoryContainer({title}){


    return (
        <div className="flex flex-col justify-end w-full h-full rounded-lg shadow-lg bg-white p-4">
            <div className="w-full">
                <p className="text-red-800 text-center text-xl">{title}</p>
            </div>
        </div>
    )
}