/**
 * @todo:
 * 1. Handle the array of services and map them correctly
 * 2. Add pagination, including depending on screensize
 */

import { Box, Skeleton, Typography } from "@mui/material";
import SearchBar from "../common/SearchBar";
import ServiceCard from "../serviceBanners/ServiceCard";


const ServicesLayouts = ({ title = "Title", services = [], search = false, loading }) => {

    let content = 
        <Box sx={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap", gap: theme => theme.spacing(2) }}>
            
                <Skeleton variant="rectangular" width={300} height={300} />
        </Box>
    // if (!loading) {content = services.map((service) => <ServiceCard key={service.id} id={service.id}/>)}
    if (!loading) {
        content = services.map((service, index) => <ServiceCard key={index} id={service.id} index={index}/>)
    }
    return (
        <Box sx={{ bgcolor: "white", p: 2, display: "flex-column" }}>
            <Box sx={{ display: "flex", pb: 2 }}>
                <Typography variant="h5">{title}</Typography>
                <Box sx={{ flexGrow: 1 }} />
                {search && <SearchBar />}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-around", overFlow: "auto", gap: theme => theme.spacing(2) }}>
                {content}
                {!loading && services.length === 0 && <Typography>No services found...</Typography>}
            </Box>
        </Box>
    );
}

export default ServicesLayouts;