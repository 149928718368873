/**
 * The form doe snot work yet, at least the update
 */

import { Box, TextField, Button, Typography } from '@mui/material';
import { patchService } from '../../utils/apiCalls/axios-service';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

function ServiceEditorPage() {

    const { mutate, isPending, isError, error, isSuccess } = useMutation({
        mutationFn: patchService
    })

    let { serviceId } = useParams();

    const onSubmit = (e) => {
        e.preventDefault();
        let data = {};
        if (e.target.name.value) {
            data.name = e.target.name.value;
        }
        if (e.target.description.value) {
            data.description = e.target.description.value;
        }
        // Call the mutation function with the data
        mutate(serviceId, data);
    }



    return (
        <Box>
            <Typography variant='h3'>Service Editor</Typography>
            <Box>
                <form noValidate autoComplete="off" onSubmit={onSubmit}>
                    <TextField name="name" id="name" label="Name" variant="outlined" fullWidth margin="normal" inputProps={{ maxLength: 255 }} />
                    <TextField name="description" id="description" label="Description" variant="outlined" multiline rows={4} fullWidth margin="normal" />
                    <Button sx={{ mt: 2 }} variant="contained" color="primary" type="submit">Save</Button>
                </form>
            </Box>
        </Box>
    )
}

export default ServiceEditorPage;