import React, {useState, useEffect} from "react";
import { useDrag, useDrop } from 'react-dnd';
import ItemMenu from "../../../../common/dnd/menu/ItemMenu";
import DraggableText from "../../../../common/dnd/draggables/DraggableText";

export default function SortableItem({ item, index, moveItem, updateItem }) {
  const [isActive, setIsActive] = useState(false);
  const [currentItem, setCurrentItem] = useState(item);
  const [isEditing, setIsEditing] = useState(false);
  const ref = React.useRef(null);

  useEffect(() => {
    setCurrentItem(item);
  }, [item]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsActive(false);
        setIsEditing(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActive]);

  const [, drop] = useDrop({
    accept: 'ITEM',
    hover(draggedItem) {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'ITEM',
    canDrag: !isActive, 
    item: (monitor) => {
      const clientOffset = monitor.getClientOffset();
      const itemRect = ref.current.getBoundingClientRect();
      const offsetX = clientOffset ? clientOffset.x - itemRect.left : 0;
      const offsetY = clientOffset ? clientOffset.y - itemRect.top : 0;
      return { 
        id: currentItem.id, 
        width: currentItem.width, 
        height: currentItem.height, 
        color: currentItem.color,
        fontSize: currentItem.fontSize,
        fontColor: currentItem.fontColor,
        text: currentItem.text,
        zIndex: currentItem.zIndex,
        index, 
        from: 'PARKING',
        offsetX,
        offsetY,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const toggleActive = () => {
    setIsActive(true);
  }

  useEffect(() => {
    if (!isActive) {
      preview(ref);
      drag(drop(ref));
    }
  }, [preview, drag, isActive, drop]);

  return (
    <div 
      ref={ref} 
      style={
        {
          opacity: isDragging ? 0.5 : 1,	
          width: 100, 
          height: 60, 
          backgroundColor: `${item.color}`,
        }
      } 
      className={`relative p-4 border rounded`} 
      onClick={toggleActive}
      onDoubleClick={() => setIsEditing(true)}
    >

      <ItemMenu 
        isOpen={isActive} 
        item={currentItem} 
        updateItem={updateItem} 
      />

      <DraggableText 
        item={currentItem} 
        updateItem={updateItem}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />

    </div>
  );
  
}
