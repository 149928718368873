import { useEffect, useState } from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader"

import LoadingSpinner from "../../../common/loading/LoadingSpinner";
import OnboardingStage from "./stages/OnboardingStage";
import AttendeesDiscussionStage from "./stages/AttendeesDiscussionStage";
import DiscussionLeaderBoard from "./stages/DiscussionLeaderBoard";
import CollaborativeStage from "./stages/CollaborativeStage";

export default function GroupSessionItem({item}) {

    const [ws, setWs] = useState(null);
    const [stageData, setStageData] = useState({});
    const authHeader = useAuthHeader();
    let token = null;
    if (authHeader !== null && authHeader !== undefined) {
        token = authHeader.split(' ')[1];
    }

    useEffect(() => {
        if (token && item) {
            const newSocket = new WebSocket(`${process.env.REACT_APP_WS_URL}collab/gs/${item.id}/?token=${token}`);
            setWs(newSocket);
            newSocket.onmessage = (event) => {
                console.log(event);
                const data = JSON.parse(event.data);
                if (data.type === 'groupsession_status') {
                    setStageData(data.message);
                }

                if (data.type === 'users_update' && data.message && data.message.users) {
                    setStageData(prev => ({ ...prev, users: data.message.users }));
                }
                
            };
    
            const pingInterval = setInterval(() => {
                if (newSocket.readyState === WebSocket.OPEN) {
                    newSocket.send(JSON.stringify({ type: 'ping', message: true }));
                }
            }, 2000);
    
            return () => {
                newSocket.close();
                clearInterval(pingInterval);
            };
        }
    }, [token, item]);
    

    if(!ws || !stageData || !item){
        return (
            <LoadingSpinner />
        )
    }

    return (
        <div className="flex flex-col h-full w-full mb-10">
            
            <div className="flex flex-row w-full justify-end self-start mb-4">
                <h1 className="text-xl text-contras-medium">Stage: {stageData.stage}</h1>
            </div>

            {stageData && stageData.is_discussion_leader && (
                <DiscussionLeaderBoard data={stageData} ws={ws} />
            )}

            {stageData && stageData.stage === 0 && !stageData.is_discussion_leader && (
                <OnboardingStage data={stageData} ws={ws} />
            )}

            {stageData && stageData.stage > 0 && stageData.stage < 4 && !stageData.is_discussion_leader && (
                <AttendeesDiscussionStage data={stageData} ws={ws}/>
            )}

            {stageData && stageData.stage === 4 && !stageData.is_discussion_leader && (
                <div className="flex flex-col items-center justify-center h-full">
                    {/* <div className="w-1/2 flex flex-col items-center  w-full">
                        {stageData?.stage_description && (
                            <p className="text-lg w-3/4 text-center">{stageData.stage_description}</p>
                        )}
                    </div> */}
                    <CollaborativeStage data={stageData} ws={ws}/>
                </div>
            )}
         
        </div>
    );
    }
