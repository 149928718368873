import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';

function VideoPlayer({ video }) {
    return (
        <Box sx={{display:"flex", alignContent:"center"}}>
        <Card sx={{ maxWidth: 800, margin: 'auto', mt: 4 }}>
            <CardContent>
                <Typography variant="h5" component="div">
                    {video.title}
                </Typography>
                </CardContent>
            <CardMedia
                component="video"
                src={video.urls[0]}
                controls
                sx={{ height: 400 }}
            />
            {video.description.length >0 && <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {video.description}
                </Typography>
            </CardContent>}
        </Card>
        </Box>
    );
}

export default VideoPlayer;
