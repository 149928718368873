import React, { useEffect, useState } from 'react';
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiArrowBack } from "react-icons/bi";
import { BiLayerMinus } from "react-icons/bi";
import { BiLayerPlus } from "react-icons/bi";
import { MdOutlineDelete } from "react-icons/md";
import { Tooltip } from 'react-tooltip'
import { BiFontSize } from "react-icons/bi";
import { BiFontColor } from "react-icons/bi";
import { BiPlus } from "react-icons/bi";
import { BiMinus } from 'react-icons/bi';

const COLORS = [
    '#003049', // Deep Navy
    '#2B2D42', // Dark Blue
    '#4A4E69', // Slate Grey
    '#8D99AE', // Grey Blue
    '#D90429', // Dark Red
    '#EF233C', // Crimson Red
    '#D62828', // Dark Red
    '#458B74', // Green 
    '#7F388B', // Purple
    '#FFFFFF', // White
];

export default function ItemMenu({ isOpen, item, updateItem }) {

    const [isMainOpen, setIsMainOpen] = useState(true);
    const [isColorOpen, setIsColorOpen] = useState(false);
    const [isFontColorOpen, setIsFontColorOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(item);

    useEffect(() => {
        setCurrentItem(item);
    }, [item]);

    const changeMenu = (event, choice) => {
        event.stopPropagation();
        if (choice === "color") {
            setIsColorOpen(true); 
            setIsFontColorOpen(false);
        } else if (choice === "main") {
            setIsColorOpen(false);
            setIsFontColorOpen(false);
        } else if (choice === "font-color") {
            setIsFontColorOpen(true);
            setIsColorOpen(false);
        }
    }

    const updateColor = (event, color) => {
        event.stopPropagation();
        updateItem(currentItem.id, { color: color });
    }

    const updateZIndex = (event, increment) => {
        event.stopPropagation();
        updateItem(currentItem.id, { zIndex: currentItem.zIndex + increment });
    }

    const deleteItem = (event) => {
        event.stopPropagation();
        updateItem(currentItem.id, null);
    }

    const increaseFontSize = (event) => {
        event.stopPropagation();
        updateItem(currentItem.id, { fontSize: currentItem.fontSize + 1 });
    }

    const decreaseFontSize = (event) => {
        event.stopPropagation();
        updateItem(currentItem.id, { fontSize: currentItem.fontSize - 1 });
    }

    const updateFontColor = (event, color) => {
        event.stopPropagation();
        updateItem(currentItem.id, { fontColor: color });
    }

    return (
        <div className="flex flex-col gap-1 absolute -translate-x-1/2 left-1/2 bottom-full justify-center items-center">
            <div 
                className={`flex flex-row ${(isColorOpen || isFontColorOpen) ? "mb-2" : "mb-6"} items-center justify-center bg-white rounded shadow-lg z-20 gap-2 ${isOpen ? 'animate-fade-in px-2 py-2 ' : 'animate-scale-out'}`} 
                style={{ transformOrigin: 'center', transition: 'transform 0.3s ease', width: 'fit-content', left: '50%'}}>
                {isMainOpen && isOpen && (
                    <>
                        <button data-tooltip-id="change-color-tooltip" data-tooltip-content="Change Background" data-tooltip-place="top" className="" onClick={(e) => changeMenu(e, "color")}>
                            <div className="rounded-full w-5 h-5 flex justify-center items-center border border-1 border-gray-500" style={{ backgroundColor: currentItem.color }}>
                            </div>
                        </button>
                        <div className="border border-l-1 border-gray-300 h-6"></div>
                        <button data-tooltip-id="change-font-color-tooltip" data-tooltip-content="Change font color" data-tooltip-place="top" className="" onClick={(e) => changeMenu(e, "font-color")}>
                            <BiFontColor size={24} style={{backgroundColor: currentItem.color, color:currentItem.fontColor}}/>
                        </button>

                        <div className="border border-l-1 border-gray-300 h-6"></div>
                        <button data-tooltip-id="change-font-size-tooltip" data-tooltip-content="Change font size" data-tooltip-place="top" className="" onClick={(e) => changeMenu(e, "font-size")}>
                            <BiFontSize size={24} />
                        </button>
                        <div className="flex flex-row rounded-lg items-center gap-2 bg-gray-100 px-1">
                            <button 
                                data-tooltip-id="increase-font-size-tooltip" 
                                data-tooltip-content="Increase font size" 
                                data-tooltip-place="top" 
                                className="text-gray-800 hover:text-gray-500" 
                                onClick={(e) => increaseFontSize(e)}
                                >
                                <BiPlus size={20} />
                            </button>
                            <div>{currentItem.fontSize}</div>
                            <button 
                                data-tooltip-id="decrease-font-size-tooltip" 
                                data-tooltip-content="Decrease font size" 
                                data-tooltip-place="top" 
                                className="text-gray-800 hover:text-gray-500" 
                                onClick={(e) => decreaseFontSize(e)}>
                                <BiMinus size={20} />
                            </button>
                        </div>

        
                        <div className="border border-l-1 border-gray-300 h-6"></div>
                        <button 
                            data-tooltip-id="decrease-layer-tooltip" 
                            data-tooltip-content="Push one layer down" 
                            data-tooltip-place="top" 
                            className="text-gray-800 hover:text-gray-500" 
                            onClick={(event) => updateZIndex(event, -1)}>
                            <BiLayerMinus size={24} />
                        </button>
                        
                        <button 
                            data-tooltip-id="increase-layer-tooltip" 
                            data-tooltip-content="Pull one layer up"
                            data-tooltip-place="top" 
                            className="text-gray-800 hover:text-gray-500" 
                            onClick={(event) => updateZIndex(event, 1)}>
                            <BiLayerPlus size={24} />
                        </button>
                        <div className="border border-l-1 border-gray-300 h-6"></div>
                        <button 
                            data-tooltip-id="remove-tooltip" 
                            data-tooltip-content="Remove" 
                            data-tooltip-place="top" 
                            className="text-gray-800 hover:text-gray-500" 
                            onClick={(e) => deleteItem(e)}>
                            <MdOutlineDelete size={24} />
                        </button>
                        <Tooltip id="change-color-tooltip"/>
                        <Tooltip id="decrease-layer-tooltip" />
                        <Tooltip id="increase-layer-tooltip" />
                        <Tooltip id="remove-tooltip" />
                        <Tooltip id="change-font-color-tooltip" />
                        <Tooltip id="change-font-size-tooltip" />
                    </>
                )}
                
            </div>
    
            {isOpen && (isColorOpen) && (
                <div className={`flex flex-row bg-white rounded shadow-lg z-20 gap-2 animate-fade-in mb-4 px-2 py-2`} style={{ transition: 'transform 0.3s ease' }}>
                    <button className="text-red-800 hover:text-blue-700" onClick={(event) => changeMenu(event, "main")}>
                        <BiArrowBack size={20} />
                    </button>
                    <div className="border border-l-1 border-gray-300 h-6"></div>
                    {COLORS.map((color, index) => (
                        <button key={index} style={{ backgroundColor: color }} className={`rounded-full w-5 h-5 flex justify-center items-center border border-1 border-gray-500`} onClick={(event) => updateColor(event, color)}>
                        </button>
                    ))}
                </div>
            )}

            {isOpen && (isFontColorOpen) && (
                <div className={`flex flex-row bg-white rounded shadow-lg z-20 gap-2 animate-fade-in mb-4 px-2 py-2`} style={{ transition: 'transform 0.3s ease' }}>
                    <button className="text-red-800 hover:text-blue-700" onClick={(event) => changeMenu(event, "main")}>
                        <BiArrowBack size={20} />
                    </button>
                    <div className="border border-l-1 border-gray-300 h-6"></div>
                    {COLORS.map((color, index) => (
                        <button key={index} style={{ backgroundColor: color }} className={`rounded-full w-5 h-5 flex justify-center items-center border border-1 border-gray-500`} onClick={(event) => updateFontColor(event, color)}>
                        </button>
                    ))}
                </div>
            )}

        </div>
    );
    
}
