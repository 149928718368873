import { Box, Grid, Typography, Button, CardMedia, Card, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { serviceStateActions } from "../../utils/store/serviceState-slice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import { styled, keyframes } from '@mui/system';
import Module from "../../components/app/modules/Module";
import EvaluationOverview from "../../components/app/evaluations/EvaluationOverview";
import ActiveItemsList from "../../components/app/modules/ActiveItemsList";
import Roadmap from "../../components/app/roadmap/Roadmap";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingIcon = styled(SettingsIcon)({
  animation: `${rotate} 2s linear infinite`,
});




function Overview() {
  const modules = useSelector((state) => state.serviceState.modules);
  let activeModules = [];
  let evaluations = [];
  let reports = []
  const authHeader = useAuthHeader()


  const requiredReportsPermissions = ["view_group_results", "view_individual_results", "add_group_results"];

  if (modules) {
    activeModules = modules.filter(module => module.items.length > 0) // Not used at the moment
    // console.log(activeModules)
    evaluations = modules.flatMap(module => module.items).filter(item => 'evaluation' in item);
    reports = evaluations.filter(evaluation =>
      requiredReportsPermissions.some(permission =>
        evaluation.user_permissions.includes(permission)
      )
    );
  }
  else {
    return (
      <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
    )
  }



  const onClickHandler = () => {
    console.log(modules)
  }

  console.log(evaluations)

  return (
    <>
      <Box sx={{ display: "flex", mb: 2 }}>
        <Typography variant="h4">Overview</Typography>
        {/* <Button onClick={onClickHandler}>click</Button> */}
      </Box>
      <Grid container spacing={2} sx={{ minWidth: "100%", pb: 4}}>

        <Grid item xs={12} sx={{ justifyItems: "center" }}>
          <Box sx={{ height: '100%', display: "flex", width: "100%", gap: 2, alignItems: "center", justifyContent: "center", width: "100%" }} >
            <Roadmap />
          </Box>
        </Grid>
        <Grid item xs={reports.length === 0 ? 12 : 7}>
          <Box sx={{ display: "flex", justifyContent: "center", bgcolor: 'lightgrey', minHeight: '40vh', width: '100%', flex: 1 }} >
            {/* <Module module={activeModules[0]} /> */}
            <ActiveItemsList modules={modules} />
          </Box>
        </Grid>
        {reports.length > 0 &&
          <Grid item xs={5}>
            <Box sx={{ bgcolor: 'lightgrey', width: '100%', fisplay: "flex", height: "100%" }}>
              <EvaluationOverview evaluations={reports} />
            </Box>
          </Grid>
        }
      </Grid>
    </>
  );
}

export default Overview;