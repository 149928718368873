import { useNavigate, useLocation, useRouteError } from "react-router-dom";
import { useEffect, useState } from "react";

function ErrorPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const error = useRouteError(); // Get the error object
  const [bounce, setBounce] = useState(false);

  const onClickHandler = () => {
    navigate("/");
  };

  useEffect(() => {
    console.log('Current route:', location.pathname);

    const timer1 = setTimeout(() => {
      setBounce(true);
    }, 5000); // Start bouncing after 2 seconds

    const timer2 = setTimeout(() => {
      setBounce(false);
    }, 10000); // Stop bouncing after 4 seconds total (2 seconds of bounce)

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [location]);

  return (
    <div className="flex flex-col w-full h-screen justify-center items-center bg-white p-4 bg-secondary-main/10">
      <div className="text-center p-6 bg-white rounded shadow-lg animate-fade-in-long">
        <h1 className="text-4xl font-bold mb-4">Oops!</h1>
        <div variant="h6" className="text-lg mb-4">
          Unfortunately, we could not render the path <span className="font-bold">"{location.pathname}" </span> you are looking for.
        </div>
        <div className="mb-8">Visit our Main Page to find the correct information you are looking for</div>
        <button onClick={onClickHandler} className={`btn btn-primary-contained ${bounce ? 'animate-pulse' : ''}`}>
          Go to Home
        </button>
      </div>
    </div>
  );
}

export default ErrorPage;
