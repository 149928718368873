import { Box } from '@mui/material';

function UsersPage() {
    return (
        <Box>
            <h1>Users</h1>
        </Box>
    )
}

export default UsersPage