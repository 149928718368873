import React, { useState, useEffect, useRef } from 'react';

const DraggableText = ({ isEditing, setIsEditing, item, updateItem }) => {
  const [isActive, setIsActive] = useState(isEditing);
  const [currentText, setCurrentText] = useState(item.text);
  const inputRef = useRef(null);
  const [currentItem, setCurrentItem] = useState(item);

  useEffect(() => {
    setCurrentItem(item);
    setCurrentText(item.text);
    setIsActive(isEditing);
  }, [item, isEditing]);

  useEffect(() => {
    if (isActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isActive]);

  const handleChange = (e) => {
    updateItem(item.id, { text: e.target.value });
    setCurrentText(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      updateItem(item.id, { text: currentText });
      setIsEditing(false);
    }
  };

  const renderText = () => {
    if (isActive) {
      return (
        <textarea
          ref={inputRef}
          className="text-center border-none outline-none h-full w-full overflow-auto custom-scroll"
          style={
            {
              backgroundColor: currentItem.color,
              fontSize: currentItem.fontSize,
              color: currentItem.fontColor,
              resize: 'none',
            }
          }
          value={currentText}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      );
    }
    return (
      <div
        className="flex flex-col justify-center items-center h-full w-full overflow-auto custom-scroll"
        style={{ backgroundColor: currentItem.color, fontSize: currentItem.fontSize, color: currentItem.fontColor }}
      >
        <p className="w-full text-center">{currentText}</p>
      </div>
    );
  };

  return renderText();
};

export default DraggableText;
