/**
 * This component is displayed in the OverviewPage under the notifications tab
 */

import { Card, CardContent, Divider, Skeleton, Stack, Typography } from "@mui/material";
import EvaluationItem from "./EvaluationItem";

function EvaluationOverview({evaluations}) {
    if (!evaluations) {
        return (
            <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
        ); // or return some fallback UI
    }
    

    return (
        <Card sx={{ display: "flex", flexDirection: "column", alignItems: "center", width:"100%", height:"100%", alignContent:"center", pb:1, px:2, boxSizing: "border-box" }}>
            <CardContent sx={{width:"100%"}}>
                <Typography variant="h5">Reports</Typography>
                <Divider/>
                <Stack spacing={1} sx={{width:"100%", pt:2}}>
                    {evaluations.map((evaluation) => <EvaluationItem key={evaluation.id} item={evaluation} />)}
                </Stack>
            </CardContent>
        </Card>
    );
}

export default EvaluationOverview;