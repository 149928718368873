import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"

function ItemPage() {
    return (
        <div className="h-full w-full">
            <Outlet />
        </div>
    )
}

export default ItemPage