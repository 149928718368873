import { Stack } from "@mui/material";
import PageText from './PageText'; // Make sure to import the PageText component

const PageContent = ({ content }) => {
    return (
        <Stack spacing={8}>
            {content.map((item, index) => (
                <Stack key={index} spacing={2}>
                    {item.map((text, i) => (
                        <PageText key={`${index}-${i}`} text={text} />
                    ))}
                </Stack>
            ))}
        </Stack>
    );
};

export default PageContent; // Don't forget to export your component