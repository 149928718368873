import React from 'react';
import { useNavigate } from "react-router-dom";

export default function ActionsContainer({actions, ws}){

    const navigate = useNavigate();
    const has_action_prev = actions.includes('prev_stage');
    const has_action_next = actions.includes('next_stage');
    const has_action_start = actions.includes('start_session');
    const has_action_end = actions.includes('end_session');
    const num_permissions = actions.length;

    const handle_next_stage = () => {
        ws.send(JSON.stringify({type: 'next_stage', message:{}}));
    }

    const handle_prev_stage = () => {
        ws.send(JSON.stringify({type: 'prev_stage', message:{}}));
    }

    const handle_submit = () => {
        navigate("../../Overview")
    }

    return (
        <div className={`flex flex-row items-center ${num_permissions > 1? "justify-between": "justify-end"} w-full gap-8 px-24 pt-8 pb-8`}>
            {has_action_start ? <button className="btn btn-primary-contained" onClick={handle_next_stage}>Start</button> : null}
            {has_action_prev ? <button className="btn btn-primary-contained" color="primary" onClick={handle_prev_stage}>Previous</button> : null}
            {has_action_end ? <button className="btn btn-primary-contained" color="primary" onClick={handle_submit}>Submit</button> : null}
            {has_action_next ? <button className="btn btn-primary-contained" color="primary" onClick={handle_next_stage}>Next</button> : null}

        </div>
    )
}