import React, { useEffect, useRef, useState } from "react";
import { useDrop } from 'react-dnd';
import DraggableItem from "./DraggableItem";
import CategoryContainer from "../background/CategoryContainer";

export default function LandscapeContainer({ items, moveItemToLandscape, moveItemWithinLandscape, updateItem }) {
  const landscapeRef = useRef(null);
  const [currentItems, setCurrentItems] = useState(items);

  useEffect(() => {
    setCurrentItems(items);
  }, [items]);


  const [{ isOver }, drop] = useDrop({
    accept: 'ITEM',
    drop: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      const clientOffset = monitor.getClientOffset();
      const landscapeBoundingRect = landscapeRef.current.getBoundingClientRect();

      if (item.from === 'PARKING') {
        const x = Math.round(clientOffset.x - landscapeBoundingRect.left - item.offsetX);
        const y = Math.round(clientOffset.y - landscapeBoundingRect.top - item.offsetY);
        item.x = x;
        item.y = y;
        moveItemToLandscape(item);
      } else {
        const x = Math.round(item.x + delta.x);
        const y = Math.round(item.y + delta.y);
        moveItemWithinLandscape(item.id, x, y);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div 
      ref={node => {drop(node); landscapeRef.current = node; }}
      className={`relative flex flex-row w-full h-full bg-white gap-2 p-2 ${isOver ? 'bg-green-900' : ''}`}
    >
      <CategoryContainer title="OPPONENT" /> 
      <CategoryContainer title="(NEUTRAL) FOLLOWER" />
      <CategoryContainer title="FAN/SUPPORTER" />

      {currentItems.map((item) => (
        <DraggableItem key={item.id} item={item} updateItem={updateItem} containerRef={landscapeRef} />
      ))}
    </div>
  );
}
