import React, { useEffect, useState, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggablePostIt from '../ui/DraggablePostIt';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';

const CollaborativeStage = ({ data, ws }) => {
  const containerRef = useRef(null);
  const [items, setItems] = useState(data?.collaborative_items || []);
  const [currentWs, setCurrentWs] = useState(null);
  const [lockedItems, setLockedItems] = useState({});
  const [uniqueUserId, setUniqueUserId] = useState(null);
  useEffect(() => {
    setUniqueUserId(uuidv4());
  }, []);


  useEffect(() => {
    if (ws) {
      setCurrentWs(ws);
    }
  }, [ws]);

  useEffect(() => {
    if (currentWs) {
      currentWs.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === 'collaborative_update_item') {
          setItems(items => {
            const updatedItems = items.map(item =>
              item.id === data.message.id ? { ...item, ...data.message } : item
            );
            return updatedItems;
          });
        }
        if (data.type === 'collaborative_add_item') {
          setItems(items => {
            const newItems = [...items, data.message];
            return newItems;
          });
        }
        if (data.type === 'collaborative_remove_item') {
          setItems(items => {
            const filteredItems = items.filter(item => item.id !== data.message.id);
            return filteredItems;
          });
        }
        if (data.type === 'collaborative_lock_item') {
          setLockedItems(lockedItems => ({ ...lockedItems, [data.message.id]: data.message.user }));
        }
        if (data.type === 'collaborative_unlock_item') {
          setLockedItems(lockedItems => {
            const newLockedItems = { ...lockedItems };
            delete newLockedItems[data.message.id];
            return newLockedItems;
          });
        }
      }
    }
  }, [currentWs, setItems, items]);
  

  const moveItem = (id, newX, newY) => {
    const item = items.find(item => item.id === id);
    const newItem = { ...item, x: newX, y: newY };
    ws.send(JSON.stringify({ type: 'collaborative_update_item', message: newItem }));
    setItems(items => items.map(item =>
      item.id === id ? newItem : item
    ));
  };

  const updateItem = (id, newItem) => {
    if (newItem === null) {
      setItems(items => items.filter(item => item.id !== id));
      ws.send(JSON.stringify({ type: 'collaborative_remove_item', message: { id } }));
      return;
    } else {
      const item = items.find(item => item.id === id);
      const updatedItem = { ...item, x: item.x, y: item.y, ...newItem };
      setItems(items => items.map(item =>
        item.id === id ? updatedItem : item
      ));
      ws.send(JSON.stringify({ type: 'collaborative_update_item', message: updatedItem }));
    }
  }

  const addItem = () => {
    const id = uuidv4();
    const newItem = { 
      id, 
      text: ``, 
      x: 100, 
      y: 100, 
      color: '#128322', 
      width: 70, 
      height: 50, 
      zIndex: 1, 
      fontSize: 12,
      fontColor: '#FFF'
    };
    setItems([...items, newItem]);
    ws.send(JSON.stringify({ type: 'collaborative_add_item', message: newItem }));
  }

  const lockItem = (id) => {
    ws.send(JSON.stringify({ type: 'collaborative_lock_item', message: { id, user: uniqueUserId } }));
  };

  const unlockItem = (id) => {
    ws.send(JSON.stringify({ type: 'collaborative_unlock_item', message: { id } }));
  };

  return (
    <div className="flex flex-col w-[1200px] h-[900px] overflow-x-auto justify-center items-end mt-4">

      <div className="pb-2">
        <button className="btn btn-primary-outlined" onClick={addItem}>Add Item</button>
      </div>

      <div className="relative w-full h-full flex flex-col " >
        <div className="flex flex-row w-full h-[50px]">
          <div className="flex w-[10%]">
          </div>
          <div className="flex w-[30%]  items-center justify-center bg-gray-100">
            <p className="text-center text-black text-lg font-bold">INDIVIDUAL</p>
          </div>
          <div className="flex w-[30%] items-center justify-center bg-gray-100">
            <p className="text-center text-black text-lg font-bold">TEAM</p>
          </div>
          <div className="flex w-[30%] items-center justify-center bg-gray-100">
            <p className="text-center text-black text-lg font-bold">ORGANISATION</p>
          </div>
        </div>

        <div className="flex flex-row w-full h-full">
          <div className="flex flex-col h-full w-[10%] ">
              <div className="flex h-[33.3%] justify-center items-center bg-red-500" >
                <p className="text-center text-white text-lg font-bold">STOP</p>
              </div>
              <div className="flex h-[33.3%] w-full justify-center items-center bg-yellow-500">
                <p className="text-center text-black text-lg font-bold">START</p>
              </div>
              <div className="flex h-[33.3%] w-full justify-center items-center bg-green-500">
                <p className="text-center text-white text-lg font-bold">CONTINUE</p>
              </div>
          </div>
          <div className="relative flex w-[90%] h-full" ref={containerRef}>
            <DndProvider backend={HTML5Backend}>
            {items.map(item => (
              <DraggablePostIt
                key={item.id}
                item={item}
                moveItem={moveItem}
                updateItem={updateItem}
                lockItem={lockItem}
                unlockItem={unlockItem}
                isLocked={lockedItems[item.id] && lockedItems[item.id] !== uniqueUserId}
                containerRef={containerRef}
              />
            ))}
            <div className="relative w-full h-full">
              <div className="grid grid-cols-3 grid-rows-3 w-full h-full">
                {Array.from({ length: 9 }).map((_, index) => {

                  const isRightMostColumn = index % 3 === 2;
                  const isBottomRow = index >= 6;
                  const isLeftMostColumn = index % 3 === 0;
                  const isTopRow = index < 3;
                  const borderClasses = `
                    ${!isRightMostColumn ? 'border-r' : ''} 
                    ${isRightMostColumn ? 'border-r' : ''} 
                    ${!isBottomRow ? 'border-b' : ''} 
                    ${isBottomRow ? 'border-b' : ''}
                    ${isLeftMostColumn ? 'border-l' : ''} 
                    ${isTopRow ? 'border-t' : ''}
                  `;

                  return (
                    <div 
                      key={index} 
                      className={`flex justify-center items-center border-gray-400 ${borderClasses}`}
                      style={{ boxSizing: 'border-box' }}
                    >
                    </div>
                  );
                })}
              </div>
            </div>

            </DndProvider>
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full justify-center items-center mt-6">
        <p className="text-center text-red-600 text-xl font-bold">Applied DE&I is what truly makes the difference</p>
      </div>
    </div>
  );
};

export default CollaborativeStage;
