import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'

export default function ModalBlank({children, isOpen, setIsOpen, closeWithDefaults=true }) {

    const handleOnClose = () => {
        if (closeWithDefaults) {
            setIsOpen(false);
        } 
    }

    return (
    <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-40" onClose={handleOnClose}>
            <TransitionChild
            as="div"
            className="fixed inset-0 z-10 bg-gray-900 bg-opacity-25 backdrop-blur-sm transition-opacity"
            enter="transition ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            aria-hidden="true"
            />
            <TransitionChild
            as="div"
            className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center px-4 sm:px-6"
            enter="transition ease-in-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in-out duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            >
            <DialogPanel className="bg-gray-light rounded-lg shadow-xl border border-gray-border" style={{ maxWidth: "95%", maxHeight: "90%", overflow: "auto" }}>
                {children}
            </DialogPanel>
            </TransitionChild>
        </Dialog>
    </Transition>
  );
}