import React, {useEffect, useState} from 'react';
import UserOnlineStatus from './UserOnlineStatus';

export default function UserOnlineStatusContainer({ data, asBox = true }) {

    const [users, setUsers] = useState([]);

    useEffect(() => {
        setUsers(data);
    }, [data]);

    
    if(asBox){
        return (
            <div className="rounded p-4 min-w-[350px] shadow-lg">
                <div className="flex flex-row items-center justify-center w-full">
                    <h2>Discussion Attendees</h2>
                </div>
                <hr className="w-full mb-4 mt-2 border-gray-300" />
                <ul>
                    {data.map((user, index) => (
                        <li key={index}>
                            <UserOnlineStatus key={index} user={user} />
                        </li>
                       
                    ))}
                </ul>
            </div>
        );
    } else {
        return (
            <div className="grid lg:grid-cols-6 md:grid-cols-4 w-full my-2 p-2">
                    {data.map((user, index) => (
                        <div className="col-span-1">
                            <UserOnlineStatus key={index} user={user} />
                        </div>
                    ))}
            </div>
        );
    }


}
