import { Box, Typography } from "@mui/material";
import TextComponent from "./TextComponent"
import AdviceComponent from "./AdviceComponent";

/**
 * 
 * @todo: Still make the ListOfTextComponent correctly. 
 */

const ListOfTextComponent = ({ item, id, onChangeAdvice }) => {
    const { content } = item;
    const { subtitle, data } = content;

    console.log("item", item)

    
    return (
        <>
            <Typography variant="subtitle2" gutterBottom>{subtitle}</Typography>
            {data.map((subItem) => {
                return (
                <Box sx={{display:"flex", flexDirection:"column", my:1}}>
                    <Typography variant="subtitle2">{subItem.title}</Typography>
                    <Typography variant="body2">{subItem.text}</Typography>
                </Box>
                )
            })}
            {content.advices && <AdviceComponent advices={content.advices} onChangeAdvice={onChangeAdvice} id={id} />}

        </>
    )
}

export default ListOfTextComponent