



export default function AttendeesDiscussionStage({data, ws}){

    return (
        <div className="flex flex-row w-full h-full justify-center items-center p-12">

            <div className="w-1/2 flex flex-col items-center justify-center w-full h-full bg-primary-main/20">
                <p className="text-2xl mb-8" >Scenario Description: </p>
                {data?.stage_description && (
                    
                    <p className="text-xl w-3/4 text-center">{data.stage_description}</p>
                )}
            </div>

            <div className="w-1/2 flex flex-col items-center justify-center w-full h-full bg-secondary-main/20">
                <p className="text-2xl mb-8" >Your Perspective: </p>
                {data?.perspective && (
                    <p className="text-xl w-3/4 text-center">{data.perspective}</p>
                )}
            </div>
        </div>
    )
}