import { AppBar, Toolbar, Box, Typography, Button, Stack, Menu, MenuItem, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { serviceStateActions, userStateActions } from "../../utils/store/serviceState-slice";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useSignOut from 'react-auth-kit/hooks/useSignOut'
import axiosInstance, { logout } from "../../utils/apiCalls/axios-auth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { styled, keyframes } from '@mui/system';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';


/**
 * 
 * @returns TopAppBar component
 * Also need to add the handlers for which links are active
 * Also need to link to the store
 */

/**
 * Just for fun
 */
const rotateClockwise = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const rotateCounterClockwise = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const RotatingLogo = styled('img')(({ rotation }) => ({
    height: '54px',
    width: '54px',
    marginRight: '16px',
    animation: `${rotation === 'clockwise' ? rotateClockwise : rotateCounterClockwise} 0.5s linear`,
}));

const TopAppBar = () => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const signOut = useSignOut()
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const [rotation, setRotation] = useState(null);
    const isDrawerOpen = useSelector(state => state.ui.drawerOpen)

    useEffect(() => {
        setRotation(isDrawerOpen ? 'counterClockwise' : 'clockwise');
    }, [isDrawerOpen]);

    let auth = typeof isAuthenticated === 'function' ? isAuthenticated() : isAuthenticated;


    const { mutate, isPending, isError, error } = useMutation({
        mutationFn: logout,
        onSuccess: () => {
            dispatch(serviceStateActions.deactivateService());
            signOut();
            document.cookie.split(";").forEach((c) => {
                document.cookie = c
                    .replace(/^ +/, "")
                    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
            queryClient.clear();
            navigate("/");
        }
    })

    const homeClickHandler = (e) => {
        e.preventDefault();
        navigate("/");
    }

    const contactClickHandler = (e) => {
        e.preventDefault();
        navigate("/app/dashboard");
    }

    const loginClickHandler = (e) => {
        e.preventDefault();
        navigate("/login");
    }

    const serviceSelectionClickHandler = (e) => {
        e.preventDefault();
        navigate("/app/service-selection");
    }

    const signOutHandler = (e) => {
        e.preventDefault();
        handleClose();
        mutate();

        // Clear cookies
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });

    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const signedInMenu = (
        <>
            <Button onClick={handleClick}>
                <AccountCircleOutlinedIcon sx={{ color: "white" }} />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={signOutHandler}>Signout</MenuItem>
            </Menu>
        </>
    )

    return (
        <AppBar position="fixed" sx={{

            background: 'linear-gradient(90deg, #E30613 0%, #661333 100%)', height: '72px', justifyContent: "center", zIndex: (theme) => theme.zIndex.drawer + 1
        }}>
            <Toolbar sx={{
                justifyContent: 'space-between',

            }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                        <RotatingLogo
                            background={true}
                            backgroundcolor="#661333"
                            component="img"
                            src="\images\Diamant_2023_Thick_Trans_white.png"
                            alt="xN logo"
                            rotation={rotation}
                        />
                        <CardMedia
                            component="img"
                            sx={{ objectFit: "contain", maxHeight: "36px", bg:true, bgColor:"white" }}
                            image="\images\xN_initials_logo-white.png"
                            alt={`Image xN initials`}
                            
                            // onError={(e) => { e.target.src = null; }}
                        />
                        {/* <Typography variant="h3" fontWeight={100} component="div" sx={{ color: 'common.white' }}>
                            xN
                        </Typography> */}
                    </Stack>
                </Box>
                <Stack direction="row" spacing={2}>
                    <Button sx={{ color: 'common.white' }} onClick={homeClickHandler}>Home</Button>
                    <Button sx={{ color: 'common.white' }} onClick={contactClickHandler}>Contact</Button>
                    {!auth && <Button sx={{ color: 'common.white' }} onClick={loginClickHandler}>Login</Button>}
                    {auth && <Button sx={{ color: 'common.white' }} onClick={serviceSelectionClickHandler}>Services</Button>}
                    {auth && signedInMenu}
                </Stack>
            </Toolbar>
        </AppBar>
    );
};

export default TopAppBar