/**
 * This Banner is used both for the service selection card, and for the sideNav. As they need different styling, use the "usage" prop to determine which styling to apply.
 * It can be either "sideNav" or "serviceCard".
 * The serviceType is used to fetch the right image for the service. The image must be named "serviceType-miniature.png" and be in the images/serviceImages folder.
 * @supportedServiceType are ServiceDEI, ServiceMentoring
 * 
 * @todo: 
 * 1. remove index after mock
 */
import { Box, Typography } from '@mui/material';

export const ServiceBanner = ({index=0, title = "Training", company = "Company", department = "Department", usage = "sideNav", serviceType = "ServiceDEI", logoUrl=null }) => {
    // Here I am setting the dimensions of the banner based on the usage prop
    // let width = 220;
    // if (usage === "serviceCard") {
    //     width = 280;
    // }
    let leftPadding = 2;
    if (usage === "sideNav") { leftPadding = 1 }
    let textBoxWidth = "100%"
    if (usage === "sideNav") { textBoxWidth = 120 }

    console.log(logoUrl);

    return (
        <Box
            sx={{
                alignItems: 'center',
                bgcolor: 'background.paper',
                px: leftPadding,
                boxShadow: 0,
                display: 'flex',
                flexDirection: 'row',
                height: 104,
                width: "100%",
                justifyContent: 'space-between',
                boxSizing: "border-box"
                // position: 'relative',
            }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: "flex",
                    flexDirection: 'row',
                    // flexGrow: 1,
                    gap: 1,
                    justifyContent: 'space-between',

                }}
            >
                <Box
                    sx={{
                        height: 80,
                        bgcolor: 'primary.main',
                        width: 16,

                    }}
                />
                <Box

                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        position: 'relative',
                        flexGrow: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: textBoxWidth


                    }}
                >
                    <Typography noWrap variant="subtitle2" sx={{ my: 0 }}>
                        {title}
                    </Typography>
                    <Typography noWrap variant="body1">{company}</Typography>
                    <Typography noWrap variant="body2">{department}</Typography>
                </Box>

            </Box>
            <Box
                component="img"
                src={logoUrl ? logoUrl : `/images/serviceImages/ServiceDEI-miniature0.png`}
                alt="Abstract Faces"
                sx={{
                    maxHeight: 89,
                    
                    justifyContent: 'flex-end',
                    alignContent: 'flex-end',

                }}
            />
        </Box>
    );
};

export default ServiceBanner;