import { Typography } from "@mui/material";

const PageText = ({ text }) => {
    const {type, content, variant, color, justify} = text;

    return (
        <Typography variant={variant} color={color} align={justify}>
            {content}
        </Typography>
    );
}

export default PageText;
