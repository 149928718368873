import { createSlice } from "@reduxjs/toolkit";

const initialState = {drawerOpen: false};

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setDrawer(state, action) {
            state.drawerOpen = action.payload;
        }
    }
});

export default uiSlice.reducer;
export const uiActions = uiSlice.actions;