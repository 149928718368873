/**
 * This component renders data from evaluations in an interactive manner.
 * @todo:
 * 1. Implement the advice type to be able to edit the advice in the evaluation.
 * 3. add the logic to download it as pdf
 * 4. maybe combine the logic for handling data changes
 */

import dummyData from '../../dummyData.json';
import { useEffect, useState } from 'react';
import { Box, Button, Skeleton, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useNavigate, useParams } from 'react-router-dom';
import DynamicEvaluationLoader from '../../components/app/evaluations/DynamicEvaluationLoader';
import EvaluationVisualizationParser from '../../components/app/evaluations/display/EvaluationVisualizationParser';
import { getEvaluation, updateEvaluation } from '../../utils/apiCalls/axios-service';

const InteractiveEvaluation = ({}) => {

    const [data, setData] = useState(null); 

    const { evaluationId, serviceId } = useParams()

    const navigate = useNavigate()
    const queryClient = useQueryClient();


    const { data: queryData, error, isError, isLoading } = useQuery({
        queryKey: ['evaluation', evaluationId],
        queryFn: () => getEvaluation({id:evaluationId, formatType:"json"}),
        staleTime: 0
    });
    
    // console.log('queryData:', queryData);

    const { mutate, isPending, isError: mutationIsError, error: mutationError, data: mutationData, isSuccess } = useMutation({
        mutationFn: updateEvaluation,
        onError: () => { console.log("error", mutationError)},
        onSuccess: (data) => {
            // console.log("success", data)
            queryClient.invalidateQueries('evaluation', evaluationId, serviceId, "Services", { refetchActive: true })
            navigate("../")
        }
    });

    // This useEffect is used to update the data when the query is successful
    useEffect(() => {
        // console.log("Data", queryData)
        setData(queryData);
    }, [queryData]);
   

    //Handling loading time
    if (data === null || data === undefined) { return <Skeleton variant="rectangular" width={"100%"} height={"100%"} /> }



    //Handling loading time
    // if (isLoading) { return <Skeleton variant="rectangular" width={"100%"} height={"100%"} >LOADING</Skeleton> }


    const handleAdviceChange = (id, newText) => {
        const newData = { ...data };
        newData.content[id].content.advices.text = newText;
        setData(newData);
    };

    const handleLikertChange = (id, newValue) => {
        // console.log(newValue, id)
        const newData = { ...data };
        newData.content[id].content.data.value = newValue;
        setData(newData);
    }

    const handleSubmit = () => {
        // console.log("Submitting data", data);
        // const newData = { content: data.content };
        // const goodData = JSON.stringify(newData);
        // console.log("Good data", goodData);
        mutate({id: evaluationId, data: data});
        

    }
    if (data === null) { return <Skeleton variant="rectangular" width={"100%"} height={"100%"} >WEIRD DATA</Skeleton> }



    return (
        <>
            <Typography variant="h4" gutterBottom>{data.meta.name}</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", mt: 4, mx:2, gap: 1, justifyContent: "space-evenly" }}>
                {Object.keys(data.content).map(key => {
                    const item = data.content[key];
                    return item && <EvaluationVisualizationParser item={item} id={key} key={key} onChangeAdvice={handleAdviceChange} onChangeLikert={handleLikertChange} />;
                })}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ margin: 3 }}
                >
                    Save and Submit
                </Button>
            </Box>

        </>
    );
}

export default InteractiveEvaluation;