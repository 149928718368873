/**
 * @todo: 
 * Make sure that it shows all evaluations and not just 1 and 2
 */

import { Box, Button, Card, CardContent, Skeleton, Stack, Typography } from '@mui/material';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import EvaluationCard from './EvaluationCard';
import { useSelector } from 'react-redux';


const ReportsList = () => {
    const navigate = useNavigate();
    const { serviceId } = useParams();

    const onClickHandler = () => {
        navigate(`/app/service/${serviceId}/reports/5`)
    }
    const modules = useSelector((state) => state.serviceState.modules);
    console.log(modules);
    let evaluations = [];

    if (modules) {
        evaluations = modules.flatMap(module => module.items).filter(item => 'evaluation' in item);
    } else {
        return <Skeleton variant="rectangular" width="100%" height="100vh" />
    }

    const visibleEvaluations = evaluations.filter(evaluation => evaluation.actions.create_group_evaluation === true || evaluation.actions.receive_group_evaluation === true || evaluation.actions.update_group_evaluation === true);

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", mb: 4 }}>
                <Typography variant="h4">Reports</Typography>
            </Box>
            {visibleEvaluations.length === 0 &&
                <Card sx={{ display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center", boxSizing: "border-box" }}>
                    <CardContent sx={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center", gap: 4 }} >
                        <Typography variant="body1">No reports available yet</Typography>
                    </CardContent>
                </Card >}
            <Stack spacing={2}>
                {/* <Button onClick={onClickHandler}>Click me to see how an evaluation would be displayed</Button> */}
                {visibleEvaluations.map((evaluation) => { return (<EvaluationCard item={evaluation} key={evaluation.id} />) })}
            </Stack>
        </>
    );
}

export default ReportsList;