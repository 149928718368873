import { useEffect } from 'react';
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader"
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated"
import axiosInstance from "./axios-auth";


const useAxiosInterceptor = () => {
    const isAuthenticated = useIsAuthenticated()
    const authHeader = useAuthHeader()
    useEffect(() => {
        const requestInterceptor = axiosInstance.interceptors.request.use(
          (config) => {
            if (isAuthenticated && authHeader) {
              config.headers['Authorization'] = authHeader;
            }
            return config;
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    
        // Eject the interceptor when the component unmounts
        return () => {
          axiosInstance.interceptors.request.eject(requestInterceptor);
        };
      }, [isAuthenticated, authHeader]); // Only re-run if isLoggedIn or authHeader changes
    };
    
    export default useAxiosInterceptor;
