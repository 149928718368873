import React, {useRef, useEffect} from "react";
import { useDrop } from 'react-dnd';
import SortableItem from "./SortableItem";
import ParkingInformationContainer from "../background/ParkingInformationContainer";

export default function ParkingContainer({ items, moveItemToParking, moveItemWithinParking, updateItem }) {

  const ref = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: 'ITEM',
    drop: (item) => {
      if (item.from !== 'PARKING') {
        moveItemToParking(item);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  useEffect(() => {
    drop(ref);
  }, [drop]);

  return (
    <div ref={ref} className={`relative w-full p-4 bg-yellow-100 ${isOver ? 'bg-yellow-200' : ''} ${items?.length === 0 ? "h-[110px]" : ""}`}>
      <div className="grid grid-cols-8 gap-4 pb-5">
        {items.map((item, index) => (
          <SortableItem 
            key={item.id} 
            item={item} 
            index={index} 
            moveItem={moveItemWithinParking} 
            updateItem={updateItem} 
            containerRef={ref}
          />
        ))}
      </div>
      
      <ParkingInformationContainer text="Important Stakeholder but don't know where to place" />
    </div>
  );
}
