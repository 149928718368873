import { AspectRatio } from "@mui/icons-material";

const prepareLineChartData = (data) => {

    return {
        labels: Object.values(data).length > 0 ? Object.values(data)[0].x : undefined,
        datasets: Object.entries(data).map(([label, dataset]) => ({
            label: label,
            data: dataset.y,
            borderColor: ['#636363',
                '#C00000',
                '#8C1F3C',
                '#8C1F3C',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'],
            backgroundColor: ['#636363',
                '#C00000',
                '#E32636',
                '#8C1F3C',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'],
            tension: 0.1
        }),
        ),
        options: {
            scales: {
                x: {
                    type: 'category',
                    labels: Object.values(data).length > 0 ? Object.values(data)[0].x : undefined
                },
                y: {
                    beginAtZero: true
                }
            }
        }
    };
}

const preparePieChartData = (data) => {
    return {
        labels: Object.keys(data),
        datasets: [{
            data: Object.values(data),
            backgroundColor: ['#636363',
                '#C00000',
                '#E32636',
                '#8C1F3C',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'],
            borderColor: ['#636363',
                '#C00000',
                '#E32636',
                '#8C1F3C',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'],
            borderwidth: 1
        }],
        options: {
            plugins: {
                legend: {
                    position: 'top',
                    align: "start"
                },
            },
        }

    }
}

const prepareBarChartData = (data) => {
    return {
        labels: Object.keys(data),
        datasets: [{
            data: Object.values(data),
            backgroundColor: [
                // '#636363',
                '#C00000',
                // '#E32636',
                // '#8C1F3C',
                // 'rgba(153, 102, 255, 0.2)',
                // 'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                // '#636363',
                '#C00000',
                // '#E32636',
                // '#8C1F3C',
                // 'rgba(153, 102, 255, 1)',
                // 'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
        }],
        options: {
            plugins: {
                legend: {
                    display: false,
                    position: 'top',
                    align: "start"
                },
            }, scales: {
                y: {
                    beginAtZero: true
                }
            }
        }
    }
}

export const prepareChartData = (data, chartType) => {
    switch (chartType) {
        case 'line_chart':
            return prepareLineChartData(data);
        case 'pie_chart':
            return preparePieChartData(data);
        case 'bar_chart':
            return prepareBarChartData(data);
        default:
            return {};
    }
}