import { Box } from '@mui/material';

function ModulesPage() {
    return (
        <Box>
            <h1>Modules</h1>
        </Box>
    )
}

export default ModulesPage