import React, { useState, useEffect } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LandscapeContainer from "./dnd/LandscapeContainer";
import ParkingContainer from "./dnd/ParkingContainer";
import HorizontalArrow from "./background/HorizontalArrow";
import VerticalArrow from "./background/VerticalArrow";
import StatusBar from "./ui/StatusBar";
import ModalBlank from "../../../common/modals/ModalBlank"
import InstructionModal from "./ui/InstructionsModal";
import ConfirmationModal from "./ui/ConfirmationModal";
import instructionsData from "./content/instructions.json";
import ActionPlanModal from "./ui/ActionPlanModal";
import { v4 as uuidv4 } from 'uuid';

const instructions = instructionsData;

export default function StakeholderLandscapeItem({item}) {

  const [landscapeItems, setLandscapeItems] = useState([]);
  const [parkingItems, setParkingItems] = useState([]);

  const [phase, setPhase] = useState(1);
  const [task, setTask] = useState(0);
  const [actionPlan, setActionPlan] = useState("");
  const [instructionModalOpen, setInstructionModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [actionPlanModalOpen, setActionPlanModalOpen] = useState(false);
  const [hasShownInstructionModal, setHasShownInstructionModal] = useState(false);

  useEffect(() => {
    if (!hasShownInstructionModal) {
      setInstructionModalOpen(true);
      setHasShownInstructionModal(true); 
      setTimeout(() => {
        setInstructionModalOpen(true);
        setHasShownInstructionModal(true); 
      }, 3000);
    }
  }, [hasShownInstructionModal]);


  const updateItem = (id, newItem) => {
    if (newItem === null) {
      setLandscapeItems(items => items.filter(item => item.id !== id));
      setParkingItems(items => items.filter(item => item.id !== id));
      return;
    } else {
      setLandscapeItems((prevItems) => prevItems.map(prevItem => prevItem.id === id ? {...prevItem, ...newItem} : prevItem));
      setParkingItems((prevItems) => prevItems.map(prevItem => prevItem.id === id ? {...prevItem, ...newItem} : prevItem));
    }
  }

  const moveItemToLandscape = (item) => {
    setLandscapeItems((prevItems) => [...prevItems, item]);
    setParkingItems((prevItems) => prevItems.filter(prevItem => prevItem.id !== item.id));
  };

  const moveItemToParking = (item) => {
    setParkingItems((prevItems) => [...prevItems, item]);
    setLandscapeItems((prevItems) => prevItems.filter(prevItem => prevItem.id !== item.id));
  };

  const moveItemWithinParking = (fromIndex, toIndex) => {
    const updatedItems = [...parkingItems];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    setParkingItems(updatedItems);
  };

  const moveItemWithinLandscape = (id, x, y) => {
    setLandscapeItems((prevItems) =>
      prevItems.map(item => item.id === id ? { ...item, x, y } : item)
    );
  };

  const addNewItem = () => {
    const id = uuidv4();
    const newItem = { 
      id, 
      text: `-`, 
      x: 100, 
      y: 100, 
      color: '#f0f0f0', 
      width: 100, 
      height: 60, 
      zIndex: 1, 
      fontSize: 12,
      fontColor: '#000'
    };
    setParkingItems((prevItems) => [...prevItems, newItem]);
  };


  const handleTaskConfirmation = () => {

    // Checking if there is a next task
    if (instructions[phase]["tasks"][task + 1]){
      setTask((prevTask) => prevTask + 1);
      setInstructionModalOpen(true);
      return;
    }

    // Checking if there is a next phase
    if (instructions[phase + 1]){
      setPhase((prevPhase) => prevPhase + 1);
      setTask(0);
      setInstructionModalOpen(true);
      return;
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>

      <div className="flex flex-col w-full h-full min-h-[900px] gap-0 justify-center items-center p-12">
      <StatusBar 
        phase={phase} 
        task={task} 
        onContinue={() => setConfirmationModalOpen(true)} 
        openInstructions={() => setInstructionModalOpen(true)}
        />

        {/* LANDSCAPE AREA */}
        <div className="flex flex-row w-full h-full gap-0">
            <VerticalArrow />
          <div className="flex flex-col w-full gap-0">
            
            <LandscapeContainer 
              items={landscapeItems} 
              moveItemToLandscape={moveItemToLandscape} 
              moveItemWithinLandscape={moveItemWithinLandscape}
              updateItem={updateItem} 
            />

            <HorizontalArrow />
          </div>
        </div>



        {/* PARKING AREA */}
        <div className="flex flex-col w-full gap-2">
          <div className="flex flex-row w-full justify-end gap-2">
            {phase && phase === 3 && (
              <button onClick={() => setActionPlanModalOpen(true)} className="btn btn-primary-contained" >Add Action Plan</button>
            )}
            <button onClick={addNewItem} className="btn btn-primary-outlined">Add Stakeholder</button>
          </div>
 
          <ParkingContainer 
            items={parkingItems} 
            moveItemToParking={moveItemToParking} 
            moveItemWithinParking={moveItemWithinParking} 
            updateItem={updateItem} 
          />

        </div>
      </div>

      {/* MODALS */}
      {phase && phase > 0 && (
        <>
          <ModalBlank isOpen={instructionModalOpen} setIsOpen={setInstructionModalOpen} closeWithDefaults={false}>
              <InstructionModal phase={phase} task={task} setPhase={setPhase} setTask={setTask} setIsOpen={setInstructionModalOpen}/>         
          </ModalBlank>
          <ModalBlank isOpen={confirmationModalOpen} setIsOpen={setConfirmationModalOpen}> 
              <ConfirmationModal phase={phase} task={task} onConfirmation={handleTaskConfirmation} setIsOpen={setConfirmationModalOpen}/>         
          </ModalBlank>
          <ModalBlank isOpen={actionPlanModalOpen} setIsOpen={setActionPlanModalOpen} closeWithDefaults={false}>
              <ActionPlanModal setIsOpen={setActionPlanModalOpen} actionPlan={actionPlan} setActionPlan={setActionPlan}/>         
          </ModalBlank>
        </>
      )}


    </DndProvider>
  );
}
