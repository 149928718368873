import axiosInstance from "./axios-auth";

export async function getServices(id = null) {
    const url = id ? `services/${id}/` : `services/`;
    // setAuthToken(token);
    const response = await axiosInstance.get(url);
    // console.log(response.data)
    // console.log(response.data)
    return response.data;

}


// Following reuqest does not work
export async function patchService(id, data) {
    const url = `services/${id}/`;
    const response = await axiosInstance.post(url, data);
    return response.data;
}

// following function is for the questionaires

export async function getEvaluation({ id, formatType }) {
    // console.log('id:', id);
    // console.log('formatType:', formatType);

    if (formatType === "json") {
        const url = `questionnaire-items/${id}/evaluation/?formatType=${formatType}&hydrate=1/`;
        const response = await axiosInstance.get(url);
        console.log('response:', response);
        return response.data;
    }
    else if (formatType === "pdf") {
        const url = `questionnaire-items/${id}/evaluation/?formatType=${formatType}/`;
        console.log('url:', url)
        const response = await axiosInstance.get(url);


        return response.data;

    }
}

export async function downloadPdf(questionnaireId) {
    const url = `questionnaire-items/${questionnaireId}/evaluation/?formatType=pdf/`;
    const response = await axiosInstance.get(url, { responseType: 'blob' });
    return response.data;
}


export async function updateEvaluation({ id, data }) {
    // console.log("This is posted data", data)
    const url = `questionnaire-items/${id}/evaluation/`;
    const response = await axiosInstance.put(url, data);
    return response.data;
}

export async function generateEvaluation(taskId) {
    const url = `questionnaire-items/${taskId}/evaluation/`;
    const response = await axiosInstance.post(url);
    return response.data;
}

export async function publishGroupEvaluation(taskId) {
    const url = `questionnaire-items/${taskId}/publish-group-evaluation/`;
    const data = { publish: true}
    const response = await axiosInstance.post(url, data);
    return response.data;
}

export async function getQuestionnaireItem(id) {
    console.log(id)
    const url = `questionnaires/`
    const data = {questionnaireitem: id}
    const response = await axiosInstance.post(url, data);
    return response.data;

}


export async function submitQuestion({answer, mode, answer_id}) {


    const url = `questions/${answer_id}/`
    if (mode === "post") {
    const url = `questions/`
    const response = await axiosInstance.post(url, answer);
    return response.data;
    }
    else if (mode === "put") {
    const url = `questions/${answer_id}/`
    const response = await axiosInstance.put(url, answer);
    return response.data;
    }
} 

export async function submitQuestionnaire({questionnaireID}) {
    const url = `questionnaires/${questionnaireID}/submit/`
    const response = await axiosInstance.post(url);
    return response.data;
}