import React, { useEffect, useState } from 'react';
import { Button, TextField, Box, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function PairsQuestion({ question, onAnswerChange }) {
    // Initialize pairs with the minimum length or the provided answers
    const initialPairs = question.answer_obj?.answer || Array(question.answer_config.min_length).fill(["", ""]);
    const [pairs, setPairs] = useState(initialPairs);


    // useEffect(() => {
    //     // Ensure there are always at least min_length pairs
    //     if (pairs.length < question.answer_config.min_length) {
    //         setPairs([...pairs, ...Array(question.answer_config.min_length - pairs.length).fill([null, null])]);
    //     }
    // }, [pairs, question.answer_config.min_length]);

    const handlePairChange = (index, key, value) => {
        const newPairs = pairs.map((pair, i) => {
            if (i === index) {
                return [key, value];
            }
            return pair;
        });
        setPairs(newPairs);
        const data = {
            question_id: question.question_id,
            content: { text: question.text },
            answer: newPairs
        };
    };

    const handleRemovePair = (index) => {
        let newPairs;
        if (pairs.length > question.answer_config.min_length) {
            newPairs = pairs.filter((_, i) => i !== index);
        } else {
            newPairs = pairs.map((pair, i) => {
                if (i === index) {
                    return ['', ''];
                }
                return pair;
            });
        }
        setPairs(newPairs);
        const data = {
            question_id: question.question_id,
            content: { text: question.text },
            answer: { newPairs }
        };
    };

    const handleAddPair = () => {
        if (pairs.length >= question.answer_config.max_length) return;
        const newPairs = [...pairs, ['', '']];
        setPairs(newPairs);
        const data = {
            question_id: question.question_id,
            content: { text: question.text },
            answer: [newPairs]
        };
    };

    const keyInputProps = {
        min: question.answer_config.key.boundaries?.min || 0,
        max: question.answer_config.key.boundaries?.max || Infinity,
        step: question.answer_config.key.boundaries?.step || 1
    };

    const valueInputProps = question.answer_config.value.type === 'integer' ? {
        min: question.answer_config.value.boundaries?.min || 0,
        max: question.answer_config.value.boundaries?.max || Infinity,
        step: question.answer_config.value.boundaries?.step || 1
    } : {};

    const handleKeyChange = (index, value) => {
        const parsedValue = question.answer_config.key.type === 'integer' ? parseInt(value, 10) : "";
        handlePairChange(index, parsedValue, pairs[index][1]);
    };

    const handleValueChange = (index, value) => {
        const parsedValue = question.answer_config.value.type === 'integer' ? parseInt(value, 10) : "";
        handlePairChange(index, pairs[index][0], parsedValue);
    };

    const handleOnBlur = () => {
        const hasInvalidPair = pairs.some(pair => pair[0] === null || pair[0] === '' || pair[1] === null || pair[1] === '');
    
        if (hasInvalidPair) {
            return;
        }
    
        const data = {
            question_id: question.question_id,
            content: { text: question.text },
            answer: pairs
        };
        onAnswerChange(question.question_id, data);
    };
    

    return (
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: 2, gap: 2 }}>
            <Typography variant="subtitle1" sx={{ marginRight: 2 }}>{question.question_id}. {question.text}</Typography>
            {pairs.map((pair, index) => (
                <Box key={index} display="flex" alignItems="center" justifyItems={"center"} marginBottom={1}>
                    <TextField
                        type={question.answer_config.key.type === 'integer' ? 'number' : 'text'}
                        label="Year"
                        value={pair[0]}
                        onChange={(e) => handleKeyChange(index, e.target.value)}
                        onBlur={handleOnBlur}
                        variant="outlined"
                        size="small"
                        style={{ marginRight: 8 }}
                        inputProps={keyInputProps}
                        error={question.answer_config.key.type === 'integer' && pair[0] !== '' &&
                        (pair[0] < question.answer_config.key.boundaries?.min || pair[0] > question.answer_config.key.boundaries?.max)}
                    />
                    <TextField
                        type={question.answer_config.value.type === 'integer' ? 'number' : 'text'}
                        label="Value"
                        value={pair[1]}
                        onChange={(e) => handleValueChange(index, e.target.value)}
                        onBlur={handleOnBlur}
                        variant="outlined"
                        size="small"
                        style={{ marginRight: 8 }}
                        inputProps={valueInputProps}
                        error={question.answer_config.value.type === 'integer' && 
                        (pair[1] < question.answer_config.value.boundaries?.min || pair[1] > question.answer_config.value.boundaries?.max)}
                    />
                    <IconButton onClick={() => handleRemovePair(index)} color="error">
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ))}
            <Box display="flex" alignItems="center" marginBottom={1}>
                {pairs.length >= question.answer_config.max_length &&
                    <Button onClick={handleAddPair} variant="contained" color="primary" disabled={pairs.length >= question.answer_config.max_length}>
                        Add Pair
                    </Button>
                }
            </Box>
        </Box>
    );
}

export default PairsQuestion;
