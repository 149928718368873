import { Typography, Box } from '@mui/material';
import AdviceComponent from './AdviceComponent';

const TextComponent = ({ item, id, onChangeAdvice }) => {

  const { content } = item;
  const { subtitle, data } = content;

  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h5" gutterBottom>
        {data.title}
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        {subtitle}
      </Typography>
      <Typography variant="body1">
        {data.text}
      </Typography>
      {content.advices && <AdviceComponent advices={content.advices} onChangeAdvice={onChangeAdvice} id={id} />}
    </Box>
  )
};

export default TextComponent;