import { Box, Button } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

function ReportsPage() {
    

    return (
        <Box>
            <Outlet />
        </Box>
    )
}

export default ReportsPage