/**
 * @module SearchBar Still need to make that the search actually works
 */


import { Box, InputBase } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import { useState } from "react";


const SearchBar = (props) => {
    const [searchTerm, setSearchTerm] = useState('')

    return (
        <Box sx={{
            position: 'relative',
            bgcolor: 'greys.light',
            alignContent: 'center',
            display: 'flex',
            '&:hover': {
                backgroundColor: "greys.ultraLight",
            },
            marginRight: 2,
            marginLeft: 0,
            width: 320,
            gap: 2,

        }}>
            <Box sx={{display: "flex", alignItems:"center", justifyItems:"center", pl: 1}}>
                <SearchIcon />
            </Box>
            <InputBase
                sx={{
                    color: 'inherit',
                    '& .MuiInputBase-input': {
                        padding: (1, 1, 1, 0),
                        // vertical padding + font size from searchIcon
                    },
                    flexGrow: 1,
                }}
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
            />
        </Box>)
}
export default SearchBar