import { Box, Typography } from "@mui/material"
import DynamicEvaluationLoader from "../DynamicEvaluationLoader"

const EvaluationVisualizationParser = ({ item, onChangeAdvice, id, onChangeLikert }) => {
    let flexValue = "1 0 100%"
    if (item.visualization.flexType === "half_width") { flexValue = "1 0 45%" }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            flex: flexValue,
            justifyItems: "space-between",
            border: 2,
            borderColor: 'primary.dark',
            padding: 1,
            boxSizing: "border-box",
        }}>
            <Typography variant="h6" sx={{color:"primary.light"}} gutterBottom>{item.content.title}</Typography>
            <DynamicEvaluationLoader item={item} onChangeAdvice={onChangeAdvice} id={id} onChangeLikert={onChangeLikert} />
        </Box>
    )
}

export default EvaluationVisualizationParser