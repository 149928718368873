import { Outlet } from "react-router-dom";



export default function IntermediateLayout({children}){

    return (
        <div className="px-32 pt-16 w-full h-full">
            <Outlet />
        </div>
    )


}