import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Checkbox, TextField, Typography } from '@mui/material';

function MultipleChoiceQuestion({ question, answer, onAnswerChange }) {

    const [newOption, setNewOption] = useState('');
    const [options, setOptions] = useState(question.answer_config?.options || {});


    const initialSelectedValues = answer?.answer?.map(a => a.key) || question.answer_obj?.answer?.map(a => a.key) || [];
    const [selectedValues, setSelectedValues] = useState(initialSelectedValues);

    const handleChange = (event) => {
        const value = event.target.value;
        let updatedValues = [];

        if (question.answer_config.is_single) {
            updatedValues = [value];
        } else {
            if (event.target.checked) {
                updatedValues = [...selectedValues, value];
            } else {
                updatedValues = selectedValues.filter((v) => v !== value);
            }
        }

        setSelectedValues(updatedValues);

        const data = {
            question_id: question.question_id,
            content: { text: question.text },
            answer: updatedValues.map((v) => ({
                key: v,
                value: options[v]
            }))
        };

        onAnswerChange(question.question_id, data);
    };

    const handleAddOption = () => {
        if (newOption.trim() === '') return;
        const keys = Object.keys(options).map(Number);
        const newKey = (Math.max(...keys) + 1).toString();
        const newOptions = { ...options, [newKey]: newOption };
        setOptions(newOptions);
        setNewOption('');
    };

    // Set direction of the radio group based on the length of the options:
    let direction = 'row';
    const allValuesAreIntegers = Object.values(options).every(Number.isInteger);
    if (!allValuesAreIntegers) {
        direction = 'column';
    }
    
    return (
        <FormControl component="fieldset" sx={{ display: "flex", alignItems: "flex-start", alignContent: "flex-start" }}>
            <FormLabel component="legend">{question.question_id}. {question.text}</FormLabel>
            <Box sx={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap', flexDirection: direction }}>
                {Object.entries(options).map(([key, value]) => (
                    <FormControlLabel
                        key={key}
                        control={
                            question.answer_config.is_single ? (
                                <Radio
                                    checked={selectedValues.includes(key)}
                                    onChange={handleChange}
                                    value={key}
                                />
                            ) : (
                                <Checkbox
                                    checked={selectedValues.includes(key)}
                                    onChange={handleChange}
                                    value={key}
                                />
                            )
                        }
                        label={value}
                    />
                ))}
            </Box>
            {question.answer_config.extendable && (
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                    <TextField
                        value={newOption}
                        onChange={(e) => setNewOption(e.target.value)}
                        placeholder="Add new option"
                        variant="outlined"
                        size="small"
                    />
                    <Button
                        onClick={handleAddOption}
                        variant="contained"
                        size="small"
                        sx={{ marginLeft: 2 }}
                    >
                        Add
                    </Button>
                </Box>
            )}
        </FormControl>
    );
}

export default MultipleChoiceQuestion;



// import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

// function MultipleChoiceQuestion({ question, answer, onAnswerChange }) {
//     const handleChange = (event) => {
//         const data = {question_id: question.question_id, values: event.target.value}
//         onAnswerChange(question.question_id, { value: event.target.value });
//     };


//     return (
//         <FormControl component="fieldset" sx={{display:"flex", alignItems:"center", alignContent:"center"}}>
//             <FormLabel component="legend" >{question.questionid}. {question.text}</FormLabel>
//             <RadioGroup
//                 name={`question-${question.question_id}`}
//                 value={answer || question.answer?.value || ''}
//                 onChange={handleChange}
//                 row

//             >
//                 <Box sx={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>

//                     {Object.entries(question.answer_config.options).map(([label, value]) => (
//                         <FormControlLabel key={label} value={value} control={<Radio />} label={label} />
//                     ))}
//                 </Box>

//             </RadioGroup>
//         </FormControl>
//     );
// }

// export default MultipleChoiceQuestion;