import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function ActionPlanModal({ actionPlan, setActionPlan, setIsOpen }) {
  const [currentActionPlan, setCurrentActionPlan] = useState(actionPlan);

  useEffect(() => {
    setCurrentActionPlan(actionPlan);
  }, [actionPlan]);

  const handleOnSave = () => {
    setIsOpen(false);
    setActionPlan(currentActionPlan);
  };

  return (
    <div className="flex flex-col w-[1000px] h-[600px] bg-white rounded-lg shadow-lg p-4">
      <div className="flex flex-row w-full h-full p-4">

        <div className="flex flex-col h-full justify-start items-center gap-12 p-8 w-1/3">
          <p className="text-xl">Guidance</p>
          <p>Who must be influenced? In what direction, how and through whom?</p>
          <p>What stakeholder relationship must be actively developed? Who and how?</p>
          <p>What is the timeline for engaging the aforementioned stakeholders?</p>
        </div>

        <div className="flex flex-col h-full w-2/3">
          <ReactQuill
            style={{ height: "100%", padding:0, margin:0}}
            value={currentActionPlan}
            onChange={setCurrentActionPlan}
            theme="snow"
            placeholder="Enter text here"
            modules={{
              toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['bold', 'italic', 'underline'],
                [{ 'align': [] }],
                ['clean'] 
              ],
            }}
          />
        </div>
      </div>
      <div className="flex flex-row w-full justify-between mt-12 px-4">
        <button className="px-4 py-2 bg-red-500 text-white rounded" onClick={() => setIsOpen(false)}>
          Cancel
        </button>
        <button className="px-4 py-2 bg-red-500 text-white rounded" onClick={handleOnSave}>
          Save
        </button>
      </div>
    </div>
  );
}
