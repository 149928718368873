import { formatDistanceToNow, parseISO } from 'date-fns';

export default function UserOnlineStatus({user}){

    const formatLastSeen = (isoDate) => {
        return formatDistanceToNow(parseISO(isoDate), { addSuffix: true });
    };

    return (
        <div className="flex flex-row w-full items-center gap-2">
            <div className="flex items-center justify-center m-0 p-0">
                <span className={`h-3 w-3 rounded-full ${user.online ? "bg-green-700" : "bg-red-700"} animate-pulse m-0`}></span>
            </div>
            <div className="flex items-center justify-center m-0 p-0">
                <span className="" style={{lineHeight: '1rem'}}>{user.first_name} {user.last_name}</span>
            </div>
        </div>
    )  
}