import { Card, CardContent, CardMedia, Divider, Typography } from "@mui/material";


function Roadmap() {
    return (
        <Card sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "100%", alignContent: "center", pb: 1, px: 2, boxSizing: "border-box" }}>
            <CardContent sx={{ width: "100%" }}>
                <Typography variant="h5">Roadmap</Typography>
                <Divider />
                <CardMedia
                    component="img"
                    image="/images/roadmap.png"
                    alt="Roadmap"
                    sx={{ maxWidth: '100%', maxHeight: '100%', pt:2 }}
                />
            </CardContent>
        </Card>
    );
}

export default Roadmap;