import { Box, Card, CardContent, Divider, Skeleton, Stack, Typography } from "@mui/material";
import Item from "./Item";

/**
 * 
 * @param {*} module 
 * @returns 
 * {id, type, context, permissions, details, items}
 * 
 * @todo:
 * 1. Replace the items to incompleted items
 */


function ActiveItemsList({ modules }) {
    if (!modules) {
        return (
            <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
        ); // or return some fallback UI
    }
    
    const items = modules.flatMap(module => module.items);

    const incompletedItems = items.filter(item => item.status !== 'COMPLETED');

    return (
        <Card sx={{ display: "flex", flexDirection: "column", alignItems: "center", width:"100%", alignContent:"center", px:2, pb: 1 }}>
            <CardContent sx={{width:"100%"}}>
                <Typography variant="h5" sx={{whiteSpace: 'nowrap'}}>Active Items</Typography>
                <Divider/>
                <Stack spacing={1} sx={{width:"100%", pt:2}}>
                    {items.map((item) => <Item key={item.id} item={item} />)}
                </Stack>
            </CardContent>
        </Card>
    );
}

export default ActiveItemsList;