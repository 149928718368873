import React, {useState, useEffect} from 'react';
import { Box, TextField, Typography } from '@mui/material';

function IntegerQuestion({ question, onAnswerChange }) {
    const [answer, setAnswer] = useState(question.answer_obj?.answer?.value || null );

    const handleOnBlur = (event) => {

        console.log(question);

        if (answer) {
            const data = {
                question_id: question.question_id,
                content: {text: question.text},
                answer: { value: parseInt(answer,10)}
            };
            onAnswerChange(question.question_id, data);
        }
        
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: 'space-between', gap: 1 }}>
            <Typography variant="subtitle1" sx={{ marginRight: 2 }}>{question.question_id}. {question.text}</Typography>
            <TextField
                type="number"
                variant="outlined"
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
                onBlur={handleOnBlur}
                inputProps={{
                    min: question.answer_config.min || 0,
                    max: question.answer_config.max || Infinity,
                    step: question.answer_config.step || 1
                }}
                sx={{ maxWidth: '80px' }} // Set a maximum width
            />
        </Box>
    );
}

export default IntegerQuestion;

