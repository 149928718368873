import React, { useState, useEffect } from "react";
import instructionsData from "../content/instructions.json";

const instructions = instructionsData;

export default function InstructionModal({phase, task, setTask, setIsOpen}){

    const [visibleTask, setVisibleTask] = useState(task);

    useEffect(() => {
        setVisibleTask(task);
    }, [task])

    const handleContinue = () => {
        if (task === 0){
            setTask((prevTask) => prevTask + 1);
            setVisibleTask((prevTask) => prevTask + 1);
        } else if (visibleTask < task){
            setVisibleTask((prevTask) => prevTask + 1);
        }
    }

    const handlePrevious = () => {
        setVisibleTask((prevTask) => prevTask - 1);
    }

    const handleClose = () => {
        setIsOpen(false);
    }


    if (visibleTask === 0){
        return (
            <div className="flex flex-col max-w-[600px] justify-center items-center h-full bg-white p-8">
                <p className="text-2xl  mb-6">Phase {phase} </p>
                <p className="text-lg text-center">{instructions[phase].introduction}</p>
                <div className="flex flex-row justify-end w-full mt-4 px-4">
                    <button className="btn btn-primary-contained"  onClick={handleContinue}>Continue</button>
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col max-w-[750px] justify-center items-center h-full bg-white p-8">
            <p className="text-2xl  mb-4 text-center">{instructions[phase]["tasks"][visibleTask]["task"]["question"]}</p>
            <p className="text-lg p-4">{instructions[phase]["tasks"][visibleTask]["task"]["description"]}</p>
            <div className="flex flex-row justify-between w-full mt-6 px-4">
                <button className="btn btn-primary-contained" onClick={handlePrevious}>Previous</button>

                {visibleTask.toString() === task.toString() && (
                    <button className="btn btn-primary-contained" onClick={handleClose}>Ok</button>
                )}

                {visibleTask.toString() !== task.toString() && (
                    <button className="btn btn-primary-contained" onClick={handleContinue}>Next</button>
                )}
                
            </div>
        </div>
    )
}