import IntegerQuestion from "./IntegerQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import PairsQuestion from "./PairsQuestion";
import TextQuestion from "./TextQuestion";

function QuestionParser({ data, answer, onAnswerChange }) {
    switch (data.answer_type) {
        case "multiple_choice":
            return <MultipleChoiceQuestion question={data} answer={answer} onAnswerChange={onAnswerChange} />;
        case "text":
            return <TextQuestion question={data} answer={answer} onAnswerChange={onAnswerChange} />;
        case "integer":
            return <IntegerQuestion question={data} answer={answer} onAnswerChange={onAnswerChange} />;
        case "pairs":
            return <PairsQuestion question={data} answer={answer} onAnswerChange={onAnswerChange} />;
        default:
            return <p>Question type not supported</p>;
    }
}

export default QuestionParser