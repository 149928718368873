import QuestionParser from "./questions/QuestionParser";
import VideoPlayer from "./otherTypes/VideoPlayer";
import TextType from "./otherTypes/TextType";

export default function TypeParser({data, type, answer, onAnswerChange}) {
    switch (type) {
        case "question":
            return <QuestionParser data={data} answer={answer} onAnswerChange={onAnswerChange} />;
        case "video":
            return <VideoPlayer video={data} />;
        case "text":
            return <TextType question={data} />;
        default:
            return <p>Type not supported</p>;
    }

}