/**
 * @todo:
 * 1. Add the right Border of the drawer
 * 2. Nees to handle which links are active when
 * 
 */

import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider, Toolbar, Box, ListItemButton, Skeleton } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { useSelector } from 'react-redux';
import ServiceBanner from '../serviceBanners/ServiceBanner';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../utils/store/ui-slice';

const drawerWidth = 240;

const Sidebar = () => {
    const serviceInfo = useSelector(state => state.serviceState);
    const [open, setOpen] = useState(false);
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    if (!serviceInfo.isServiceActive) {
        return <Skeleton variant="rectangular" width={drawerWidth} height="100vh" />
    }

    const handleDrawerOpen = () => {
        setOpen(!open);
        dispatch(uiActions.setDrawer(!open))
    };



    const isActiveRoute = (route) => {
        const pathname = location.pathname.endsWith("/") ? location.pathname.slice(0, -1) : location.pathname;
        const currentRouteLastPart = pathname.split("/").pop();
        return currentRouteLastPart === route;

    }

    const overviewClickHandler = () => {
        navigate(`/app/service/${serviceInfo.serviceId}/overview`)
    }

    const reportsClickHandler = () => {
        navigate(`/app/service/${serviceInfo.serviceId}/reports`)
    }

    const modulesClickHandler = () => {
        navigate(`/app/service/${serviceInfo.serviceId}/modules`)
    }

    const usersClickHandler = () => {
        navigate(`/app/service/${serviceInfo.serviceId}/users`)
    }

    const serviceEditorClickHandler = () => {
        navigate(`/app/service/${serviceInfo.serviceId}/edit`)
    }

    const playgroundClickHandler = () => {
        navigate(`/app/service/${serviceInfo.serviceId}/playground`)
    }

    return (

        <Drawer
            variant="permanent"
            open={open}
            sx={{

                width: open ? drawerWidth : theme => theme.spacing(7),
                '& .MuiDrawer-paper': {
                    justifyContent: 'space-between',
                    overflowX: 'hidden',
                    width: open ? drawerWidth : theme => theme.spacing(7),
                    transition: theme => theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                },

            }}
        >
            <Toolbar />

            <Box sx={{ display: "flex", bgcolor: "greys.light", flexDirection: "row", justifyContent: "flex-end" }}>
                <IconButton onClick={handleDrawerOpen} sx={{ margin: theme => theme.spacing(1) }}>
                    {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", bgcolor: "greys.light", flexGrow: 1 }}>
                {serviceInfo.isServiceActive &&
                    <>
                        <Box sx={{ p: 0 }}>
                            {open && <ServiceBanner title={serviceInfo.serviceTitle} company={serviceInfo.serviceCompany} department={serviceInfo.serviceDepartment} usage="sideNav" />}

                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContents: "flex-end" }}>
                            <ListItemButton onClick={serviceEditorClickHandler} sx={{ backgroundColor: isActiveRoute('edit') ? 'primary.main' : 'inherit', color: isActiveRoute('edit') ? 'white' : 'inherit' }}>
                                <ListItemIcon>
                                    <SettingsIcon sx={{ color: isActiveRoute('edit') ? 'white' : 'inherit' }} />
                                </ListItemIcon>
                                {open && <ListItemText primary="Edit service" />}
                            </ListItemButton>
                        </Box>
                    </>}


                <Divider />

                <List>
                    {/* Add more ListItem elements here as needed */}
                    <ListItemButton onClick={overviewClickHandler} sx={{ backgroundColor: isActiveRoute('overview') ? 'primary.main' : 'inherit', color: isActiveRoute('overview') ? 'white' : 'inherit' }}>
                        <ListItemIcon  >
                            <DashboardIcon sx={{ color: isActiveRoute('overview') ? 'white' : 'inherit' }} />
                        </ListItemIcon>
                        {open && <ListItemText primary="Overview" />}
                    </ListItemButton>

                    <ListItemButton onClick={reportsClickHandler} sx={{ backgroundColor: isActiveRoute('reports') ? 'primary.main' : 'inherit', color: isActiveRoute('reports') ? 'white' : 'inherit' }}>
                        <ListItemIcon>
                            <AssessmentIcon sx={{ color: isActiveRoute('reports') ? 'white' : 'inherit' }} />
                        </ListItemIcon>
                        {open && <ListItemText primary="Reports" />}
                    </ListItemButton>

                    <ListItemButton onClick={modulesClickHandler} sx={{ backgroundColor: isActiveRoute('modules') ? 'primary.main' : 'inherit', color: isActiveRoute('modules') ? 'white' : 'inherit' }}>
                        <ListItemIcon>
                            <AssignmentIcon sx={{ color: isActiveRoute('modules') ? 'white' : 'inherit' }} />
                        </ListItemIcon>
                        {open && <ListItemText primary="Modules" />}
                    </ListItemButton>

                    {serviceInfo.userPermissions.includes('view_associates') &&
                        <ListItemButton onClick={usersClickHandler} sx={{ backgroundColor: isActiveRoute('users') ? 'primary.main' : 'inherit', color: isActiveRoute('users') ? 'white' : 'inherit' }}>
                            <ListItemIcon>
                                <GroupIcon sx={{ color: isActiveRoute('users') ? 'white' : 'inherit' }} />
                            </ListItemIcon>
                            {open && <ListItemText primary="Users" />}
                        </ListItemButton>}

                    {/* {serviceInfo.userServiceRole[0] !== "Opiniated Facilitator" &&
                        <ListItemButton onClick={playgroundClickHandler} sx={{ backgroundColor: isActiveRoute('playground') ? 'primary.main' : 'inherit', color: isActiveRoute('playground') ? 'white' : 'inherit' }}>
                            <ListItemIcon>
                                <GroupIcon sx={{ color: isActiveRoute('playground') ? 'white' : 'inherit' }} />
                            </ListItemIcon>
                            {open && <ListItemText primary="USER PLAYGROUND" />}
                        </ListItemButton>} */}

                </List>
                <Divider />
            </Box>

            <Box sx={{ bgcolor: "greys.light", alignItems: "stretch" }}>
                <List>
                    <Divider />
                    {/* Add more ListItem elements here as needed */}
                    <ListItemButton>
                        <ListItemIcon>
                            <ContactSupportOutlinedIcon />
                        </ListItemIcon>
                        {open && <ListItemText primary="Help and Contact" />}
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        {open && <ListItemText primary="Logout" />}
                    </ListItemButton>
                </List>
            </Box>
            {/* Place other list items or footer elements here */}
        </Drawer>
        // </Box>
    );
};

export default Sidebar;