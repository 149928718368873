/**
 * @todo:
 * 1. Fix the refreshing of the token
 */

import createStore from 'react-auth-kit/createStore';
import createRefresh from 'react-auth-kit/createRefresh';
import axios from 'axios';
import { setAuthToken } from '../apiCalls/axios-auth';

const refresh = createRefresh({
    interval: 5, // The time in min to refresh the Access token,
    refreshApiCallback: async (param) => {
      const payload = {refresh: param.refreshToken}
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token/refresh/`, payload, {
          headers: {'Authorization': `Bearer ${param.authToken}`}
        })
        console.log("Refreshing")
        setAuthToken(response.data.access)
        return {
          isSuccess: true,
          newAuthToken: response.data.access,
          newAuthTokenExpireIn: 60,
          newRefreshTokenExpiresIn: 1200
        }

      }
      catch(error){
        console.error(error)
        return {
          isSuccess: false
        } 
      }
    }
  })

const authStore = createStore({
    authName:'_auth',
    authType:'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
    refresh: refresh
   })

   export default authStore
   