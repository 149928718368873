import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {isServiceActive: false, serviceId: null, serviceType: null, serviceTitle: null, serviceCompany: null, serviceDepartment: null, modules: null, userServiceRole: null, userPermissions: null};

const serviceStateSlice = createSlice({
    name: "serviceState",
    initialState,
    reducers: {
        activateService(state, action) {
            state.isServiceActive = true;
            state.serviceId = action.payload.id;
            state.serviceType = action.payload.serviceType;
            state.serviceTitle = action.payload.title;
            state.serviceCompany = action.payload.company;
            state.serviceDepartment = action.payload.department;
            state.modules = action.payload.modules;
            state.userServiceRole = action.payload.userServiceRole;
            state.userPermissions = action.payload.userPermissions;
        },

        printState(state) {
            console.log(current(state))
        },

        deactivateService(state) {
            Object.assign(state, initialState)
        }
    }
})

export default serviceStateSlice.reducer;
export const serviceStateActions = serviceStateSlice.actions;