import React, { useState, useEffect } from "react";
import { BiHelpCircle } from "react-icons/bi";

import instructionsData from "../content/instructions.json";
const instructions = instructionsData;

export default function StatusBar({phase, task, onContinue, openInstructions}){
    const [currentPhase, setCurrentPhase] = useState(phase)
    const [currentTask, setCurrentTask] = useState(task)

    useEffect(() => {
        setCurrentPhase(phase)
        setCurrentTask(task)
    }, [phase, task])


    if(currentTask === 0){
        return (
            <div className="flex flex-row justify-end w-full">
                <button onClick={openInstructions} className="btn btn-primary-contained">
                    <div className="flex flex-row gap-2 items-center">
                        <p>Re-read Instructions</p>
                        <BiHelpCircle size={24} />
                    </div>
             
                </button>
            </div>
        )
    }

    return (
        <div className="flex flex-row justify-between items-center w-full">
            
            <div className="flex flex-row gap-1 justify-center items-center">
                <p className="text-xl ">Phase: {currentPhase}</p>
            </div>
            {currentTask > 0 && (
            <div className="flex flex-row gap-4 items-center justify-center">
                <div className="flex flex-row items-center gap-1">
                    <button onClick={openInstructions} className="text-gray-400 rounded hover:text-gray-600">
                        <BiHelpCircle size={26} />
                    </button>
                    <p className="text-lg text-red-800">Task {currentTask}: {instructions[currentPhase]["tasks"][currentTask]["task"]["question"]}</p>
                </div>
                
                <button onClick={onContinue} className="btn btn-primary-contained">Continue</button>
            </div>  
            )}


        </div>
    )
}