import React, { useEffect, useState, useRef } from "react";
import { useDrag } from 'react-dnd';
import ItemMenu from "../../../../common/dnd/menu/ItemMenu";
import DraggableText from "../../../../common/dnd/draggables/DraggableText";
import DraggableResize from "../../../../common/dnd/draggables/DraggableResize";

export default function DraggableItem({ item, updateItem, containerRef }) {
  const [isActive, setIsActive] = useState(false);
  const [currentItem, setCurrentItem] = useState(item);
  const [currentContainerRef, setCurrentContainerRef] = useState(containerRef);
  const [isEditing, setIsEditing] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setCurrentItem(item);
    setCurrentContainerRef(containerRef);
  }, [item, containerRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsActive(false);
        setIsEditing(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActive]);

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'ITEM',
    item: {
      id: currentItem.id,
      x: currentItem.x,
      y: currentItem.y,
      width: currentItem.width,
      height: currentItem.height,
      color: currentItem.color,
      fontSize: currentItem.fontSize,
      fontColor: currentItem.fontColor,
      text: currentItem.text,
      zIndex: currentItem.zIndex,
      from: 'LANDSCAPE'
    },
    canDrag: !isActive,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const toggleActive = (event) => {
    setIsActive(true);
  };

  useEffect(() => {
    preview(ref);
    drag(ref);
  }, [preview, drag, isActive]);

  const handleResize = (newDimensions) => {
    setCurrentItem(prev => ({ ...prev, ...newDimensions }));
  };

  const handleResizeEnd = (newDimensions) => {
    updateItem(currentItem.id, { ...currentItem, ...newDimensions });
  };

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        left: `${currentItem.x}px`,
        top: `${currentItem.y}px`,
        opacity: isDragging ? 0.5 : 1,
        width: `${currentItem.width}px`,
        height: `${currentItem.height}px`,
        backgroundColor: `${currentItem.color}`,
        zIndex: `${currentItem.zIndex}`,
      }}
      className="p-4 border rounded"
      onClick={toggleActive}
      onDoubleClick={() => setIsEditing(true)}
    >
      <ItemMenu
        isOpen={isActive}
        item={currentItem}
        updateItem={updateItem}
      />

      <DraggableResize
        isOpen={isActive}
        item={currentItem}
        updateItem={updateItem}
        containerRef={currentContainerRef}
        onResize={handleResize}
        onResizeEnd={handleResizeEnd}
      />

      <DraggableText 
        item={currentItem} 
        updateItem={updateItem} 
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />

    </div>
  );
}
