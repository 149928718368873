import React from 'react';
import UserOnlineStatusContainer from '../ui/UserOnlineStatusContainer';
import ActionsContainer from "../ui/ActionsContainer";
import DiscussionRecommendationsContainer from '../ui/DiscussionRecommendationsContainer';
import LoadingSpinner from '../../../../common/loading/LoadingSpinner';

export default function OnboardingStage({data, ws}){

    return (
        <div className="flex flex-col items-center justify-start h-full mt-12 w-full ">
            <div className="max-w-[800px]">
                {data?.stage_name && (
                    <div className="flex flex-row items-center justify-center w-full">
                        <h1 className="text-3xl">{data.stage_name}</h1>
                    </div>
                )}

                {data?.stage_description && (
                    <div className="flex flex-row items-center justify-center w-full mt-3">
                        <p>{data.stage_description}</p>
                    </div>
                )}

                <div className="flex flex-row items-center justify-center w-full mt-12">
                    <div className="flex flex-col items-center justify-center w-full">

                        <div className="flex flex-row items-center justify-center w-full mb-12">
                            <UserOnlineStatusContainer data={data.users} />
                        </div>

                        <LoadingSpinner />

                    </div>
                </div>

            </div>
       
        </div>
    )
}