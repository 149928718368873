import React, { useState, useEffect } from "react";

export default function DraggableResize({ isOpen, item, updateItem, onResize, onResizeEnd }) {
  const [isActive, setIsActive] = useState(false);
  const [resizing, setResizing] = useState(false);
  const [resizeCorner, setResizeCorner] = useState(null);
  const [dimensions, setDimensions] = useState({ width: item.width, height: item.height });
  const [currentItem, setCurrentItem] = useState(item);

  useEffect(() => {
    setCurrentItem(item);
    setDimensions({ width: item.width, height: item.height });
    setIsActive(isOpen);
  }, [item, isOpen]);

  const onMouseDown = (e, corner) => {
    if (isActive) {
      setResizing(true);
      setResizeCorner(corner);
    }
  };

  const onMouseMove = (e) => {
    if (resizing && resizeCorner) {
      let newWidth = dimensions.width;
      let newHeight = dimensions.height;
      let newX = currentItem.x;
      let newY = currentItem.y;

      switch (resizeCorner) {
        case 'top-left':
          newWidth = Math.max(50, dimensions.width - e.movementX);
          newHeight = Math.max(50, dimensions.height - e.movementY);
          newX = currentItem.x + e.movementX;
          newY = currentItem.y + e.movementY;
          break;
        case 'top-right':
          newWidth = Math.max(50, dimensions.width + e.movementX);
          newHeight = Math.max(50, dimensions.height - e.movementY);
          newY = currentItem.y + e.movementY;
          break;
        case 'bottom-left':
          newWidth = Math.max(50, dimensions.width - e.movementX);
          newHeight = Math.max(50, dimensions.height + e.movementY);
          newX = currentItem.x + e.movementX;
          break;
        case 'bottom-right':
          newWidth = Math.max(50, dimensions.width + e.movementX);
          newHeight = Math.max(50, dimensions.height + e.movementY);
          break;
        default:
          break;
      }

      const newDimensions = { width: newWidth, height: newHeight, x: newX, y: newY };
      setCurrentItem(prev => ({ ...prev, x: newX, y: newY }));
      setDimensions({ width: newWidth, height: newHeight });

      // Update dimensions instantly
      onResize(newDimensions);
    }
  };

  const onMouseUp = () => {
    if (resizing) {
      setResizing(false);
      const newDimensions = { width: dimensions.width, height: dimensions.height, x: currentItem.x, y: currentItem.y };
      // Final update to the global state
      onResizeEnd(newDimensions);
      setResizeCorner(null);
    }
  };

  useEffect(() => {
    if (resizing) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [resizing, dimensions, resizeCorner]);

  return (
    <>
      {isActive && (
        <>
          <div
            className="border border-1 border-red-400 absolute"
            style={{ top: -6, left: -6, right: -6, bottom: -6, cursor: 'move' }}
          />

          {['top-left', 'top-right', 'bottom-left', 'bottom-right'].map(corner => (
            <div
              key={corner}
              onMouseDown={(e) => onMouseDown(e, corner)}
              className="rounded-full bg-red-400 border border-red-400"
              style={{
                position: 'absolute',
                width: '10px',
                height: '10px',
                ...(corner === 'top-left' && { cursor: 'nw-resize', top: -10, left: -10 }),
                ...(corner === 'top-right' && { cursor: 'ne-resize', top: -10, right: -10 }),
                ...(corner === 'bottom-left' && { cursor: 'sw-resize', bottom: -10, left: -10 }),
                ...(corner === 'bottom-right' && { cursor: 'se-resize', bottom: -10, right: -10 }),
              }}
            />
          ))}
        </>
      )}
    </>
  );
}
