/**
 * @todo:
 * 1. Add a list of services to select from
 * 2. Make the add service button functional
 * 3. Make the searchbar functional
 * 4. Filter the services by action requested or not
 * 5. Add sorters, such as by date added or so....
*/

import React from 'react';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import ServiceBanner from '../../components/serviceBanners/ServiceBanner';
import ServicesLayouts from '../../components/service-selection/ServicesLayouts';
import { fetchUserInfo } from '../../utils/apiCalls/http-auth';
import { useQuery } from '@tanstack/react-query';
import { getServices } from '../../utils/apiCalls/axios-auth';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useNavigate } from 'react-router-dom';


function ServiceSelectionPage() {
  // const authHeader = useAuthHeader()
  // if (authHeader) {const token = authHeader.split(' ')[1]}

  const navigate = useNavigate()
  const role = useAuthUser().role
  const {data, isLoading, isError, error} = useQuery({
    queryKey: ["Services"],
    queryFn: () => getServices(),
    staleTime:0, 
  })

  const newServiceHandler = () => {
    navigate("/app/service-selection/new-service")
  }

  let activeServices = []
  if (!isLoading) {activeServices = data.results}
  if (!isLoading) {console.log(activeServices)}

  return (
    <div className="h-full w-full">
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Typography variant="h4" sx={{ mb: 4, mt: 8 }}>Select a Service</Typography>
      {role === "Interns" && <Button variant="outlined" color="primary" sx={{ mt: 4 }} onClick={newServiceHandler}>Create a Service</Button>}
      </Box>

      {/* <Grid container spacing={3} sx={{ justifyContent: "space-evenly", alignItems: "center" }}>
        {[1, 2].map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item} >
            <ServiceBanner />
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={4} key={3} >
          <ServiceBanner title='Card Games' company="MC Donald's" department='Somethin kuz' />
        </Grid>
      </Grid> */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4, pb: 4 }}>
        <ServicesLayouts title='Action Requested' services={activeServices} loading={isLoading}/>
        <ServicesLayouts title='Active Services' services={activeServices} search={true} loading={isLoading} />
      </Box>
    </div>
  );
}

export default ServiceSelectionPage;
