/**This Handles the LoginModal, although it has its own page
 * @todo: This should be moved to the AuthPage.js 
 * TODO:
*/



import { DialogTitle, DialogContent, TextField, IconButton, DialogActions, Button, Typography, Stack, Box, CircularProgress } from '@mui/material';
import { OutlinedInput, InputLabel, InputAdornment, FormControl } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { userStateActions } from '../../utils/store/serviceState-slice';
import { login, loginJWT } from '../../utils/apiCalls/http-auth';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { fetchLoginJWT, fetchUserInfo } from '../../utils/apiCalls/axios-auth';

const LoginForm = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const signIn = useSignIn()
    const submitButtonRef = useRef(null);

    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const usernameChangeHandler = (event) => {
        setUsername(event.target.value);
    }
    const passwordChangeHandler = (event) => {
        setPassword(event.target.value);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            loginAttemptHandler(event);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [username, password]);


    // The following handles the queries for checking the credentials. Still need to work on the errors!!!!
    const { mutate, isPending, isError, error, data, isSuccess } = useMutation({
        mutationFn: fetchLoginJWT,
        onError: () => { setPassword(""); setUsername("") },
        onSuccess: (data) => {
            console.log("success")
            loginHandler(data)
        }
    });

    const loginHandler = async (tokens) => {

        const userInfo = await fetchUserInfo(tokens.access)
        // console.log(window.location.hostname)
        // console.log(userInfo)
        if (signIn({
            auth: { token: tokens.access},
            refresh: tokens.refresh,
            userState: { firstName: userInfo.first_name, uId: userInfo.id, role: userInfo.groups[0].name, username: userInfo.username, email: userInfo.email}
        })) { navigate("/app/service-selection") }
        else { console.log("error upon signin") }
    }
    


    // Displays the password or not
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    /** Checks the credentials. Will Be replaces by a check to the backend. This also updates the redux states if the login actually worked,
    * 
    */
    const loginAttemptHandler = (event) => {
        event.preventDefault();
        console.log("trying login")
        if (username === "" || password === "") { return }
        let loginDetails;
        if (username.includes("@")) { loginDetails = { email: username, password: password } } else { loginDetails = { username: username, password: password } }
        mutate(loginDetails)
    }

    return (

        <Stack spacing={4} width={{ xs: 360, sm: 540, md: 720 }} alignContent={"center"}>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Username/Email"
                type="email"
                fullWidth
                variant="outlined"
                error={isError}
                sx={{ pb: 1 }}
                onChange={usernameChangeHandler}
                value={username}
            />

            <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="input-password">Password</InputLabel>
                <OutlinedInput
                    id="input-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                    error={isError}
                    onChange={passwordChangeHandler}
                    value={password}

                />
            </FormControl>
            {isError && <Typography> Check your Credentials and try again...</Typography>}
            <Stack direction={"row"} display={"flex"} justifyContent={"center"}>
                {!isPending && !isSuccess && <>
                    {/* <Button onClick={props.onCancel}>Cancel</Button> */}
                    <Button onClick={loginAttemptHandler} variant='contained' ref={submitButtonRef}>Login</Button>
                </>}
                {isPending &&
                    <Box sx={{ display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center" }}>
                        
                        <CircularProgress sx={{ml:2}} />
                    </Box>}
                
            </Stack>
        </Stack>
    );
}

export default LoginForm

