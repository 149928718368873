
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { serviceStateActions } from '../../utils/store/serviceState-slice';

function PlaygroundPage() {
    const dispatch = useDispatch();
    dispatch(serviceStateActions.printState())

    return (
        <Box>
            <h1>PLAYGROUND! Have fun!</h1>
        </Box>
    )
}

export default PlaygroundPage