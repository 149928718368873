/**
 * @file A card component that displays the details of an evaluation.
 * @todo: 
 * 1. Have a way to know if the eval was edited, and shared to the internals
 */

import { Box, Button, Card, CardContent, Divider, Skeleton, Stack, Typography } from "@mui/material";
import EvaluationItem from "./EvaluationItem";
import EvaluationCardActions from "./EvaluationCardActions";

function EvaluationCard({ item }) {
    // console.log(item);
    // if (!item) {
    //     return (
    //         <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
    //     ); // or return some fallback UI
    // }
    ;
    const { description, name, status, evaluation, actions } = item;
    const { create_group_evaluation, receive_group_evaluation, update_group_evaluation } = actions


    let descriptionText = description.length > 0 ? description : "A description of this report";



    return (
        <Card sx={{ display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center", boxSizing: "border-box" }}>
            <CardContent sx={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center", gap: 4 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2, boxSizing: "border-box", width:"40%" }}>
                    <Typography variant="h6">{name}</Typography>
                    <Divider orientation="vertical" flexItem />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", width: "100%", gap: 2, justifyContent: "space-between", alignItems: "center", boxSizing: "border-box" }}>
                    <Typography variant="body1">{descriptionText}</Typography>
                    <Box sx={{ display: "flex", gap: 1, justifyContent: "space-between", alignItems: "center" }}>
                        {(create_group_evaluation || receive_group_evaluation || update_group_evaluation) && (
                            <EvaluationCardActions id={item.id} status={status} evaluation={evaluation} actions={actions} />
                        )}
                    </Box>
                </Box>
            </CardContent>
        </Card>

    );
}

export default EvaluationCard;