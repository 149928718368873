import { Box, Button } from "@mui/material";
import { downloadPdf, generateEvaluation, getEvaluation, publishGroupEvaluation } from "../../../utils/apiCalls/axios-service";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useCheckAsyncRequest from "../../../utils/hooks/useCheckAsyncRequest";

const EvaluationCardActions = ({ id, status, evaluation, actions }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { serviceId } = useParams();
    const { create_group_evaluation, receive_group_evaluation, update_group_evaluation } = actions;

    const { taskStatus, checkAsyncRequest } = useCheckAsyncRequest();
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const [isGeneratingEvaluation, setIsGeneratingEvaluation] = useState(false);
    const [isShared, setIsShared] = useState(false);

    const { mutate: mutateGetPdf } = useMutation({
        mutationFn: getEvaluation,
        onError: (error) => { console.log(error) },
        onSuccess: async (data) => {
            if ("task_id" in data) {
                checkAsyncRequest(data.task_id).then(() => {
                    queryClient.invalidateQueries(['Services', serviceId, "evaluation", id], { refetchActive: true });
                    setIsGeneratingPdf(false);
                }).catch(error => {
                    console.error('Error checking task status:', error);
                });
            }
        },
    });

    const { mutate: mutatePdfDownload } = useMutation({
        mutationFn: downloadPdf,
        onSuccess: (data) => {
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'evaluation.pdf');
            document.body.appendChild(link);
            link.click();
        },
    });

    const { mutate: mutateGenerateEvaluation } = useMutation({
        mutationFn: generateEvaluation,
        onSuccess: async (data) => {
            await checkAsyncRequest(data.task_id).then(() => {}).catch(error => {
                console.error('Error checking task status:', error);
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries(['Services', serviceId], { refetchActive: true });
            setIsGeneratingEvaluation(false);
        }
    });

    // Handlers
    const generateEvaluationHandler = () => {
        mutateGenerateEvaluation(id);
        setIsGeneratingEvaluation(true);
    };

    const viewHandler = () => {
        navigate(`./${id}/`);
    };

    const generatePdfHandler = () => {
        setIsGeneratingPdf(true);
        mutateGetPdf({ id: id, formatType: "pdf" });
    };

    const downloadPdfHandler = () => {
        mutatePdfDownload(id);
    };

    const shareHandler = () => {
        publishGroupEvaluation(id).then(() => {
            setIsShared(true);
        });
    };

    // Conditional Rendering Logic
    if (!evaluation && !create_group_evaluation && !receive_group_evaluation && !update_group_evaluation) {
        return null;
    }

    if (!evaluation && !create_group_evaluation) {
        return <Button variant="outlined" sx={{ padding: 0.5 }} disabled>Not Available</Button>;
    }

    if (!evaluation && create_group_evaluation) {
        return isGeneratingEvaluation ? (
            <Button variant="outlined" sx={{ padding: 0.5 }} disabled>Generating...</Button>
        ) : (
            <Button variant="outlined" sx={{ padding: 0.5 }} onClick={generateEvaluationHandler}>Generate Report</Button>
        );
    }

    // Shared buttons logic
    let sharedButtons;
    if ((create_group_evaluation || update_group_evaluation) && isShared) {
        sharedButtons = (
            <>
                <Button variant="outlined" sx={{ padding: 0.5 }} onClick={viewHandler}>View</Button>
                <Button variant="outlined" sx={{ padding: 0.5 }} disabled>Shared!</Button>
            </>
        );
    } else if ((create_group_evaluation || update_group_evaluation) && !isShared){
        sharedButtons = (
            <>
                <Button variant="outlined" sx={{ padding: 0.5 }} onClick={viewHandler}>Edit</Button>
                <Button variant="outlined" sx={{ padding: 0.5 }} onClick={shareHandler}>Share</Button>
            </>
        );
    } else {
        sharedButtons = (
            <>
                <Button variant="outlined" sx={{ padding: 0.5, width:"100%" }} onClick={viewHandler}>View</Button>
            </>
        );
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                {sharedButtons}
            </Box>
            {(receive_group_evaluation || create_group_evaluation) && (
                evaluation.has_pdf ? (
                    <Button variant="outlined" sx={{ padding: 0.5 }} onClick={downloadPdfHandler}>Download PDF</Button>
                ) : (
                    isGeneratingPdf ? (
                        <Button variant="outlined" sx={{ padding: 0.5 }} disabled>Generating PDF...</Button>
                    ) : (
                        <Button variant="outlined" sx={{ padding: 0.5 }} onClick={generatePdfHandler}>Generate PDF</Button>
                    )
                )
            )}
        </Box>
    );
};

export default EvaluationCardActions;



// import { Box, Button } from "@mui/material";
// import { downloadPdf, generateEvaluation, getEvaluation, publishGroupEvaluation } from "../../../utils/apiCalls/axios-service";
// import { useNavigate, useParams } from "react-router-dom";
// import { useState } from "react";
// import { useMutation, useQueryClient } from "@tanstack/react-query";
// import useCheckAsyncRequest from "../../../utils/hooks/useCheckAsyncRequest";

// const EvaluationCardActions = ({ id, status, evaluation, actions }) => {
//     const navigate = useNavigate()
//     const queryClient = useQueryClient();
//     const { serviceId } = useParams();
//     const { create_group_evaluation, receive_group_evaluation, update_group_evaluation } = actions

//     const { taskStatus, checkAsyncRequest } = useCheckAsyncRequest()
//     const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)
//     const [isGeneratingEvaluation, setIsGeneratingEvaluation] = useState(false)
//     const [isShared, setIsShared] = useState(false)
//     // console.log(evaluation)

//     const { mutate: mutateGetPdf, isPending: isPendingPdf, data: dataPdf, isSuccess: isSuccessPdf, error } = useMutation({
//         mutationFn: getEvaluation,
//         onError: (error) => { console.log(error) },
//         onSuccess: async (data) => {
//             console.log(dataPdf)
//             console.log("success fetching pdf", data)
//             if ("task_id" in data) {
//                 checkAsyncRequest(data.task_id).then(() => {
//                     queryClient.invalidateQueries(['Services', serviceId, "evaluation", id], { refetchActive: true });
//                     setIsGeneratingPdf(false);
//                 }).catch(error => {
//                     console.error('Error checking task status:', error);
//                 });
//             }
//         },

//     });

//     const { mutate: mutatePdfDownload } = useMutation({
//         mutationFn: downloadPdf,
//         onSuccess: (data) => {
//             console.log("success fetching pdf", data)
//             const url = window.URL.createObjectURL(data);
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', 'evaluation.pdf');
//             document.body.appendChild(link);
//             link.click();
//         },
//     })

//     const { mutate: mutateGenerateEvaluation, isPending: isPendingGenerateEvaluation, data: dataGenerateEvaluation, isSuccess: isSuccessGenerateEvaluation } = useMutation({
//         mutationFn: generateEvaluation,
//         onSuccess: (data) => {
//             console.log("success fetching evaluation", data)
//             checkAsyncRequest(data.task_id).then(() => { return }).catch(error => {
//                 console.error('Error checking task status:', error);
//             })

//         },
//         onSettled: () => {
//             queryClient.invalidateQueries(['Services', serviceId], { refetchActive: true });
//             setIsGeneratingEvaluation(false);;
//         }
//     });

//     // console.log(evaluation)

//     const generateEvaluationHandler = () => {
//         mutateGenerateEvaluation(id)
//         setIsGeneratingEvaluation(true);
//     }


//     const viewHandler = () => {
//         // getEvaluation(id, "json").then((data) => {
//         //     console.log(data);
//         //     navigate(`./${id}/`)
//         // })
//         navigate(`./${id}/`)
//     }

//     const generatePdfHandler = () => {
//         setIsGeneratingPdf(true);
//         mutateGetPdf({ id: id, formatType: "pdf" })
//     }

//     const downloadPdfHandler = () => {
//         mutatePdfDownload(id)
//     }

//     const shareHandler = () => {
//         publishGroupEvaluation(id).then(() => {
//             setIsShared(true)
//         })
//     }



//     if (evaluation === null && create_group_evaluation === false && receive_group_evaluation === false && update_group_evaluation === false) {
//         return
//         <>
//         </>
//     }

    


//     // The Following is a placeholder until the sharing is done:
//     let sharedButtons =
//         <>
//             <Button variant="outlined" sx={{ padding: 0.5 }} onClick={viewHandler} >Edit</Button>
//             <Button variant="outlined" sx={{ padding: 0.5 }} onClick={shareHandler}>Share</Button>
//         </>
//     if (isShared) {
//         sharedButtons =
//             <>
//                 <Button variant="outlined" sx={{ padding: 0.5 }} onClick={viewHandler} >View</Button>
//                 <Button variant="outlined" sx={{ padding: 0.5 }} disabled={isShared} onClick={shareHandler}>Shared!</Button>
//             </>
//     }


//     if (evaluation === null && !create_group_evaluation) {
//         return (<Button variant="outlined" sx={{ padding: 0.5 }} disabled>Not Available</Button>)
//     }
//     else if (evaluation === null && create_group_evaluation) {
//         return isGeneratingEvaluation ? (
//             <Button variant="outlined" sx={{ padding: 0.5 }} disabled>Generating...</Button>
//         ) : (
//             <Button variant="outlined" sx={{ padding: 0.5 }} onClick={generateEvaluationHandler}>Generate Report</Button>
//         )
//     }

//     else {
//         return (
//             <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
//                 <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
//                     {sharedButtons}
//                 </Box>
//                 {(receive_group_evaluation || create_group_evaluation) && (
//                     evaluation.has_pdf ? (
//                         <Button variant="outlined" sx={{ padding: 0.5 }} onClick={downloadPdfHandler}>Download PDF</Button>
//                     ) : (
//                         isGeneratingPdf ? (
//                             <Button variant="outlined" sx={{ padding: 0.5 }} disabled>Generating PDF...</Button>
//                         ) : (

//                             <Button variant="outlined" sx={{ padding: 0.5 }} onClick={generatePdfHandler}>Generate PDF</Button>
//                         )
//                     ))}
//             </Box>)
//     }
// }

// export default EvaluationCardActions;