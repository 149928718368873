/**
 * This is the differrent evaluations as displayed in the EvaluationOverview component
 * @todo Implement the handleGenerateReport and handleDownloadPdf functions
 */
import { Box, Button, Typography } from "@mui/material";
import { create } from "@mui/material/styles/createTransitions";
import { useNavigate, useParams } from "react-router-dom";

function EvaluationItem({ item }) {
    const navigate = useNavigate();
    const { serviceId } = useParams();
    const { name, evaluation, status, actions } = item;
    const { create_group_evaluation, receive_group_evaluation, update_individual_evaluation} = actions;

    const handleNavigateReports = () => {
        navigate(`/app/service/${serviceId}/reports/`)
    }

    let statusText = "Unavailable"
    let active = false;
    if (create_group_evaluation || evaluation !== null && update_individual_evaluation) {
        statusText = "manage"
        active = true;
    }
    if (receive_group_evaluation || evaluation !== null) {
        statusText = "View"
        active = true
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between", gap: 0.5 }}>
            <Box sx={{ display: "flex" }}>
                <Typography sx={{flexWrap:"wrap", overflowWrap: 'break-word'}} variant="body1">{name}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent:"center", alignItems: "center", gap: 0.5 }}>
                <Button onClick={handleNavigateReports} disabled={!active}>{statusText}</Button>
                
            </Box>
        </Box>
    )
}

export default EvaluationItem;