import instructionsData from "../content/instructions.json";
const instructions = instructionsData;

export default function InstructionModal({phase, task, onConfirmation, setIsOpen}){

    const handleClose = () => {
        setIsOpen(false);
    }

    const handleConfirmation = () => {
        setIsOpen(false);
        setTimeout(() => {
            onConfirmation();
        }, 1000);
    }

    return (
        <div className="flex flex-col max-w-[600px] justify-center items-center h-full bg-white p-8">
            <p className="text-lg text-center mb-4 px-4">{instructions[phase]["tasks"][task]["task"]["validation"]}</p>
            <div className="flex flex-row justify-between w-full mt-6">
                <button className="btn btn-primary-contained" onClick={handleClose}>No, go back</button>
                <button className="btn btn-primary-contained" onClick={handleConfirmation}>Yes, continue</button>
            </div>
        </div>
    )
}
