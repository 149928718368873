

export default function DiscussionRecommendationsContainer({data}){


    return (
        <>
            <p className="text-2xl mb-8" >Discussion Recommendations: </p>
            {data?.stage_description && (
            <div className="flex flex-col items-start justify-start w-full px-8 mb-4">
                {data.discussion_recommendations.map((recommendation, index) => (
                    <p key={index} className="text-lg" >{recommendation}</p>
                ))}
            </div>
            )}
        </>
    )
}