import { Box, Stack, Typography } from "@mui/material";
import PageContent from "../../components/website/PageContent";
import { useEffect, useState } from "react";



function HomePage() {
  const [content, setContent] = useState([]);

  useEffect(() => {
    fetch('/homePageContent.json')
      .then(response => response.json())
      .then(data => setContent(data));
  }, []);

  console.log("Rendering HomePage");

  return (
    <>
      <Typography variant="h1" justify={"left"} color={"#C00000"} sx={{ mb: 4, mt:8 }}>Welcome to xN</Typography>
      
      <Box sx={{ display: "flex", flexDirection:"column", gap:4, my:2, flexGrow:1 }}>
        <PageContent content={content} />
      </Box>
    </>
  );
}

export default HomePage;
// function HomePage() {

//   console.log("Rendering HomePage");

//   return (
//     <>
//         <Typography variant="h4" sx={{ mb: 4, mt:8 }}>Welcome to the Homepage</Typography>
      
//       <Box sx={{ display: "flex", flexDirection:"column", gap:4, flexGrow:1 }}>
//         {/* <Typography variant="body1" >A very cool text about us</Typography>
//         <Typography variant="h1">h1</Typography>
//         <Typography variant="h2">h2</Typography>
//         <Typography variant="h3">h3</Typography>
//         <Typography variant="h4">h4</Typography>
//         <Typography variant="h5">h5</Typography>
//         <Typography variant="h6">h6</Typography>
//         <Typography variant="subtitle1">subtitle1</Typography>
//         <Typography variant="subtitle2">subtitle2</Typography>
//         <Typography variant="body1">body1</Typography>
//         <Typography variant="body2">body2</Typography>
//         <Typography variant="caption">caption</Typography>
//         <Typography variant="overline">overline</Typography>
//         <Typography variant="button">button</Typography>
//          */}

//          <PageContent content={} />
//       </Box>
//     </>
//   );
// }
// export default HomePage;