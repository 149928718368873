import { Box, Skeleton, Typography } from "@mui/material"
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import QuestionnaireItem from "./questionnaireItem/QuestionnaireItem2";
import GroupSessionItem from "./groupsessionItem/GroupSessionItem";
import StakeholderLandscapeItem from "./stakeholderlandscapeItem/StakeholderLandscapeItem"

function ItemSelector() {
    const { itemId } = useParams();

    const modules = useSelector((state) => state.serviceState.modules)
    if (!modules) return (<Skeleton variant="rectangular" width={"100%"} height={"100%"} />)

    const allItems = modules.flatMap(module => module?.items);
    const item = allItems.find(item => item.id === parseInt(itemId));

    const itemTypeSelector = (item) => {
        // console.log("item", item)
        switch (item.type) {
            case "QuestionnaireItem":
                return <QuestionnaireItem item={item} />
            case "GroupSessionItem":
                return <GroupSessionItem item={item} />
            case "StakeholderLandscapeItem":
                return <StakeholderLandscapeItem item={item} />
            default:
                return <div>Unsupported type</div>
        }
    }

    return (
        <div className="flex flex-col h-full w-full pb-10 px-8">
            {/* <Box sx={{ display: "flex", flexDirection:"column", alignItems:"center", height:"100px", gap: 1, justifyContent: "space-evenly", bgcolor: "greys.ultraLight"}}>
                <Typography>{item.name}</Typography>
            </Box> */}
            <div className="bg-white h-full w-full mt-4">
                {itemTypeSelector(item)}
            </div>
        </div>

            

    )
}

export default ItemSelector