import React, { useState } from 'react';
import { Avatar, Box, Button, InputAdornment, TextField, Typography } from '@mui/material';

const AdviceComponent = ({ advices, item = null, onChangeAdvice, id }) => {
  const [text, setText] = useState(advices.text);
  const [isEdited, setIsEdited] = useState(false); // This is used to determine if the advice has been edited or not
  const [wasSaved, setWasSaved] = useState(false); // This is used to determine if the advice has been saved or not

  const handleChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= advices.max_length) {
      setText(newText);
      setIsEdited(true); // This propagates changes up to the parent component
      setWasSaved(false);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    onChangeAdvice(id, text);
    setIsEdited(false);
    setWasSaved(true);
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
      {item && item.content && item.content.subtitle && <Typography gutterBottom variant="subtitle2">{item.content.subtitle}</Typography> }
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Avatar src="/images/FinalDiamant_2023.png" alt="Description" sx={{ marginRight: 2 }} />
        <TextField
          fullWidth
          multiline
          minRows={3}
          value={text}
          onChange={handleChange}
          variant="outlined"
          placeholder="Enter your advice here..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="caption" sx={{ position: 'absolute', bottom: 0, right: 8 }}>
                  {`${text.length}/${advices.max_length} characters`}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
        {text.length === advices.max_length && (
          <Typography color="error" variant="caption">
            You have reached the character limit.
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", pt: 1 }}>
        {isEdited && (<Button onClick={handleSave}>Save</Button>)}
        {wasSaved && (
          <Typography color="green" variant="body2">
            Saved!
          </Typography>
        )}
      </Box>

    </Box>
  );
};

export default AdviceComponent;