/**
 * Page for the Auth. There is a problem with the login that goes to the side on too small screen sizes
 */

import { Box, Stack, Typography } from "@mui/material";
import LoginForm from "../../components/auth/LoginForm";


function LoginPage() {


  return (
        <Box sx={{ display: "flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
          <Typography sx={{mb:4, mt:8}} variant="h4">Login</Typography>
          <LoginForm />
        </Box>
  );
}
export default LoginPage;
