import React, {useState} from 'react';
import { Box, TextField, Typography } from '@mui/material';

function TextQuestion({ question, onAnswerChange }) {

    const [answerValue, setAnswerValue] = useState(question.answer_obj?.answer?.value || '')
    
    const handleChange = (event) => {
        const data = {
            question_id: question.question_id,
            content: {text: question.text},
            answer: { value: event.target.value }
        };
        onAnswerChange(question.question_id, data);
    };

    const handleOnBlur = (event) => {
        if (answerValue !== "") {
            const data = {
                question_id: question.question_id,
                content: {text: question.text},
                answer: { value: event.target.value }
            };
            onAnswerChange(question.question_id, data);
        }
    }

    

    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: 'space-between', alignItems: "flex-start", gap: 1 }}>
            <Typography variant="subtitle1" sx={{ marginRight: 2 }}>{question.question_id}. {question.text}</Typography>
            <TextField
                variant="outlined"
                fullWidth
                value={answerValue}
                onChange={(event) => setAnswerValue(event.target.value)}
                onBlur={handleOnBlur}
                inputProps={{
                    minLength: question.answer_config.min_length || 0,
                    maxLength: question.answer_config.max_length || Infinity
                }}
            />
        </Box>
    );
}

export default TextQuestion;


// import { Box, TextField, Typography } from '@mui/material';

// function TextQuestion({ question, answer, onAnswerChange }) {
//     const handleChange = (event) => {
        
//         onAnswerChange(question.question_id, { value: event.target.value });
//     };
//     return (
//         <Box sx={{ display: "flex", flexDirection: "column", justifyContent: 'space-between', alignItems: "flex-start", gap: 1 }}>
//             <Typography variant="subtitle1" sx={{ marginRight: 2 }}>{question.question_id}. {question.text}</Typography>
//             <TextField
//                 // label={question.text}
//                 variant="outlined"
//                 fullWidth
//                 value={answer?.value || question.answer?.value || ''}
//                 onChange={handleChange}
//                 inputProps={{
//                     minLength: question.answer_config.min_length,
//                     maxLength: question.answer_config.max_length
//                 }}
//             />
//         </Box>
//     );
// }

// export default TextQuestion