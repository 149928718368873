// This creates the routes to be rendered in the app

import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";

import HomePage from "../../pages/website/HomePage.js";
import RootLayout from "../../layouts/RootLayout.js";
import IntermediateLayout from "../../layouts/IntermediateLayout.js";
import ErrorPage from "../../pages/ErrorPage.js";
import ServiceSelectionPage from "../../pages/services/ServiceSelectionPage.js";
import LoginPage from "../../pages/website/LoginPage.js";
import AppLayout from "../../layouts/AppLayout.js";
import SiteLayout from "../../layouts/SiteLayout.js";
import RequireAuth from '@auth-kit/react-router/RequireAuth'
import OverviewPage from "../../pages/app/OverviewPage.js";
import NewServicePage from "../../pages/services/NewServicePage.js";
import UsersPage from "../../pages/app/UsersPage.js";
import ReportsPage from "../../pages/app/ReportsPage.js";
import ModulesPage from "../../pages/app/ModulesPage.js";
import ServiceEditorPage from "../../pages/app/ServiceEditorPage.js";
import InteractiveEvaluation from "../../pages/app/InteractiveEvaluation.js";
import ReportsList from "../../components/app/evaluations/ReportsList.js";
import PlaygroundPage from "../../pages/app/PlaygroundPage.js";
import ItemPage from "../../pages/app/ItemPage.js";
import ItemSelector from "../../components/app/items/ItemSelector.js";

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <SiteLayout />,
        children: [
          { index: true, element: <HomePage /> },
          { path: 'login', element: <LoginPage /> }
        ]
      },
      {
        path: 'app',
        element: <RequireAuth fallbackPath="/login"><Outlet /></RequireAuth>,
        children: [
          { index: true, element: <Navigate to="/app/service-selection" replace /> },
          {
            path: 'service-selection', element: <SiteLayout />, children: [
              { index: true, element: <ServiceSelectionPage /> },
              { path: 'new-service', element: <NewServicePage /> },

            ]
          },
          {
            path: 'service', element: <AppLayout />, children: [
              {
                path: ':serviceId', element: <Outlet />, children: [
                  {
                    path: 'item', element: <ItemPage />, children: [
                      { path: ':itemId', element: <ItemSelector />}
                    ]
                  },

                  {
                    path: '', element: <IntermediateLayout />, children: [
                      { path: 'overview', element: <OverviewPage /> },
                      { path: 'users', element: <UsersPage /> },
                      {
                        path: 'reports', element: <ReportsPage />, children: [
                          { index: true, element: <ReportsList />},
                          { path: ':evaluationId', element: <InteractiveEvaluation /> }
                        ]
                      },
                      { path: 'playground', element: <PlaygroundPage />},
                      { path: 'modules', element: <ModulesPage /> },
                      { path: 'edit', element: <ServiceEditorPage /> }
                    ]
                  }
                ]
                  
              }
            ]
          },
        ]
      }
    ]
  }
]);

export default router;
