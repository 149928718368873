import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

const SiteLayout = () => {

    return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, bgcolor: "greys.ultraLight", flexGrow:1, minHeight:"100%"}}>
            {/* Left Sidebar */}
            <Box sx={{ display: "flex", flexDirection:"column", width: { xs: '0%', md: "8%", lg: '15%' }, height:"100%", bgcolor: 'grey.200' }} />

            {/* Contents */}
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow:1, height: "100%"}}>
                <Outlet />
            </Box>

            {/* Right Sidebar */}
            <Box sx={{ display: "flex", width: { xs: '0%', md: "8%", lg: '15%'}, height:"100%", bgcolor: 'grey.200' }} />
        </Box>
    );
}

export default SiteLayout;