import React from "react";

const VerticalArrow = ({}) => {
  const height = 5;
  const arrowSize = height * 4;

  return (
    <div className="relative flex items-center pb-[60px] pt-[20px]">
      <div className="absolute top-1/2 left-1/2 pb-10 pl-24 transform -translate-x-1/2 rotate-[-90deg] text-[#C00000] font-bold ">
        Influence
      </div>

      <div className="flex flex-col justify-center items-center w-full h-full">
        <div
          className="w-0 h-0 border-r-[2.5rem] border-l-[2.5rem] border-transparent border-b-[5rem] border-b-[#C00000]"
          style={{ height: `${arrowSize / 2}px`, borderWidth: `${arrowSize / 2}px ${arrowSize / 2}px ${arrowSize}px` }}
        ></div>
        <div className="w-[6px] bg-[#C00000] h-full">
          <div className="w-full h-[4px] mx-[1px]"></div>
        </div>
      </div>
    </div>
  );
};

export default VerticalArrow;
