import React, { useRef, useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import ItemMenu from '../../../../common/dnd/menu/ItemMenu';
import DraggableText from "../../../../common/dnd/draggables/DraggableText";
import DraggableResize from "../../../../common/dnd/draggables/DraggableResize";

function calculateFinalWithinContainer(delta, item, containerRect) {

  const newX = item.x + delta.x;
  const newY = item.y + delta.y;

  // Check if the new position is within the container 
  const finalX = Math.min(Math.max(newX, 0), containerRect.width - item.width);
  const finalY = Math.min(Math.max(newY, 0), containerRect.height - item.height);

  return {
      x: finalX,
      y: finalY
  };
}


const DraggablePostIt = ({ item, moveItem, updateItem, lockItem, unlockItem, isLocked, containerRef }) => {
  
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [resizing, setResizing] = useState(false);
  const [resizeCorner, setResizeCorner] = useState(null);
  const [dimensions, setDimensions] = useState({ width: item.width, height: item.height });
  const [currentItem, setCurrentItem] = useState(item);
  const [currentContainerRef, setCurrentContainerRef] = useState(containerRef);
  const [isEditing, setIsEditing] = useState(false);


  useEffect(() => {
    setCurrentItem(item);
    setDimensions({ width: item.width, height: item.height });
    setCurrentContainerRef(containerRef);
  }, [item, containerRef]);

  const [, drag, preview] = useDrag({
    type: 'ITEM',
    item: () => {
      if (!isLocked) {
        lockItem(currentItem.id);
      }
      return {
        id: currentItem.id,
        x: currentItem.x,
        y: currentItem.y,
        width: currentItem.width,
        height: currentItem.height,
        color: currentItem.color
      };
    },
    canDrag: () => !isActive && !isLocked,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      
      if (delta && !resizing) {
        const containerRect = currentContainerRef.current.getBoundingClientRect();
        const finalPosition = calculateFinalWithinContainer(delta, item, containerRect);
        moveItem(item.id, finalPosition.x, finalPosition.y);
      }
      unlockItem(currentItem.id);
    },
  });

  useEffect(() => {
    if (!isActive) {
      preview(ref);
      drag(ref);
    }
  }, [preview, drag, isActive]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsActive(false);
        setIsEditing(false);
        if (isActive) {
          unlockItem(currentItem.id);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActive]);

  const toggleActive = (e) => {
    setIsActive(true);
    lockItem(currentItem.id);
  };

  const handleResize = (newDimensions) => {
    setCurrentItem(prev => ({ ...prev, ...newDimensions }));
  };

  const handleResizeEnd = (newDimensions) => {
    updateItem(currentItem.id, { ...currentItem, ...newDimensions });
  };

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        left: `${currentItem.x}px`,
        top: `${currentItem.y}px`,
        width: `${currentItem.width}px`,
        height: `${currentItem.height}px`,
        cursor: `${!isActive ? 'move' : ''}`,
        backgroundColor: currentItem.color,
        zIndex: currentItem.zIndex,
        opacity: isLocked ? 0.5 : 1
      }}
      onClick={toggleActive}
      onDoubleClick={() => setIsEditing(true)}
      className={`relative flex flex-col justify-center p-2 rounded-md shadow-lg ${isLocked ? 'pointer-events-none' : ''}`}
    >
      <ItemMenu isOpen={isActive} item={currentItem} updateItem={updateItem} />
      
      <DraggableResize
        isOpen={isActive}
        item={currentItem}
        updateItem={updateItem}
        containerRef={currentContainerRef}
        onResize={handleResize}
        onResizeEnd={handleResizeEnd}
      />

      <DraggableText isEditing={isEditing} setIsEditing={setIsEditing} item={currentItem} updateItem={updateItem} />
    </div>
  );
};

export default DraggablePostIt;
