/* AppLayout will be displayed when a User is logged in, in the protected routes of the /app */

import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";



import { Box, Container, Grid, Skeleton } from "@mui/material";
import Sidebar from "../components/navigationBars/SideBar";
import { getServices } from "../utils/apiCalls/axios-auth";
import { serviceStateActions } from "../utils/store/serviceState-slice";


function AppLayout() {
    const isServiceActive = useSelector((state) => state.serviceState.isServiceActive);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let { serviceId } = useParams();

    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["Services", { serviceId }],
        queryFn: () => getServices(serviceId),
        staleTime: 0, 
    })

    useEffect(() => {
        if (data) {
            const serviceInfo = {
                id: serviceId,
                title: data.name,
                company: data.group.department.company.name,
                department: data.group.department.name,
                serviceType: data.type,
                modules: data.modules,
                userPermissions: data.user_permissions,
                userServiceRole: data.user_service_role
            };
            dispatch(serviceStateActions.activateService(serviceInfo));
            console.log(serviceInfo)
        }
    }, [data, dispatch, getServices]);


    return (
        <Box  sx={{flexGrow: 1, display: 'flex', justifyContent: "center", height:"100%", width:"100%"}}>
            <Sidebar />
            <Box sx={{ display: "flex-column", justifyContent: "center", height:"100%",  width:"100%", pb:2 }}>
                <Outlet/>
            </Box>
        </Box>
    );
}

export default AppLayout;
