/*
Main file. The stores, clients, and router are defined in the utils folder
*/

import './App.css';
import './styles/custom.css';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux'
import { QueryClientProvider } from '@tanstack/react-query';
import AuthProvider from 'react-auth-kit';

import theme from './ui/theme';
import router from './utils/router/router';
import store from './utils/store';
import { queryClient } from './utils/apiCalls/http';
import authStore from './utils/auth/authStore';
import useAxiosInterceptor from './utils/apiCalls/axiosInterceptors';

function App() {
  return (
    <AuthProvider store={authStore}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </ThemeProvider>
      </Provider>
    </AuthProvider>
  );
}

export default App;
