import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export const setAuthToken = (token) => {
    if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axiosInstance.defaults.headers.common['Authorization'];
    }
};

export default axiosInstance;

/**
 * 
 * The following function tries to login the user and returns the JWT access and refresh tokens
 */
export async function fetchLoginJWT(userInfo) {
    console.log(process.env.REACT_APP_API_URL);
    const response = await axiosInstance.post('/auth/token/', 
        userInfo
    );
    return response.data
}

/**
 * 
 * The following function is the first one called following login, and needs to set the authorization header for the axios instance.
 * It is required to set the userState for react-Auth-kit
 * It also sets the AuthToken for the axios instance
 */
export async function fetchUserInfo(token) {
    setAuthToken(token)
    const response = await axiosInstance.get('/auth/user/')
    return response.data
}

export async function logout() {
    const response = await axiosInstance.post('/auth/logout/')
    return response
}


export async function getServices(id = null) {
    const url = id ? `services/${id}/` : `services/`;
    const response = await axiosInstance.get(url);
    // console.log(response.data)
    // console.log("response", response.data)
    return response.data;
}