import { Outlet } from "react-router-dom";

import TopAppBar from "../components/navigationBars/TopAppBar";
import { Box } from "@mui/material";
import useAxiosInterceptor from "../utils/apiCalls/axiosInterceptors";


function RootLayout() {

  // The follwing is the interceotor function that checks if a user is logged in and has a token. If they do, but the page is refreshed, the authorisation headers will be added again
  useAxiosInterceptor();
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height:"100vh", width:'100%', overflow:'auto'}}>
      <Box sx={{ height: '64px', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <TopAppBar />
      </Box>  

      <Box sx={{width:"100%", flex: '1 1 auto', height: 'calc(100vh - 64px)'}}>
        <Outlet />
      </Box>
  </Box>
  );
}

export default RootLayout;