import { prepareChartData } from "../../../utils/functions/prepareChartData";
import AdviceComponent from "./display/AdviceComponent";
import ChartComponent from "./display/ChartComponent";
import ImageComponent from "./display/ImageComponent";
import LikertScaleComponent from "./display/LikertScaleComponent";
import ListOfTextComponent from "./display/ListOfTextComponent";
import TextComponent from "./display/TextComponent";

/**
 * 
 * @param {*} param0 
 * @returns 
 * @todo:
 * Gotta check how we spell the subtitle
 */



const DynamicEvaluationLoader = ({ item, onChangeAdvice, onChangeLikert, id }) => {
  const { visualization} = item;

  switch (visualization.containerType) {
    case 'text':
      return <TextComponent item={item} onChangeAdvice={onChangeAdvice} id={id} />;
    case 'chart':
      // const chartData = prepareChartData(data, visualization.chartType); // Implement this function based on your data structure
      return <ChartComponent type={visualization.chartType} item={item} onChangeAdvice={onChangeAdvice} id={id}/>;
    case 'static_images_with_subscription':
      return <ImageComponent item={item} onChangeAdvice={onChangeAdvice} id={id}/>;
    case "advice_only":
      return <AdviceComponent item={item} advices={item.content.advices} onChangeAdvice={onChangeAdvice} id={id} />;
    case "likert_scale":
      return <LikertScaleComponent item={item} id={id} onChange={onChangeLikert} onChangeAdvice={onChangeAdvice}/>;
    case "list_of_text":
      return <ListOfTextComponent item={item} id={id} onChangeAdvice={onChangeAdvice}/>

    default:
      return <div>Unsupported type</div>;
  }
};

export default DynamicEvaluationLoader;