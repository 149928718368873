/**
 * @todo
 * 
 * Still have to create the whole layout and validate the logic
 */
import { Box, TextField, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function NewServicePage() {
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
    }

    const backHandler = () => {
        navigate('/app/service-selection');
    }

    return (
        <Box>
            <Typography variant="h4">New Service</Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <TextField name="group" label="Group" variant="outlined" fullWidth margin="normal" />
                <TextField name="name" label="Name" variant="outlined" fullWidth margin="normal" />
                <TextField name="description" label="Description" variant="outlined" multiline rows={4} fullWidth margin="normal" />
                <Button variant="contained" color="primary" type="submit">Validate</Button>
            </form>
            <Button variant="outlined" color="secondary" onClick={backHandler}>Back</Button>
        </Box>
    )
}

export default NewServicePage;