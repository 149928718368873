import { useState } from 'react';
import { Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Box } from '@mui/material';
import AdviceComponent from './AdviceComponent';

const LikertScaleComponent = ({ item, onChange, id, onChangeAdvice }) => {
  const { content } = item;
  const { subtitle, data } = content;

  const [value, setValue] = useState(data.value);

  const handleChange = (event) => {
    const val = parseInt(event.target.value, 10);
    setValue(event.target.value);
    onChange(id, val);
  };

  return (
    <Box sx={{ margin: 2, border: '1px solid #ccc', padding: 2, borderRadius: 2, display:"flex", flexDirection:"column" }}>
      <Typography variant="subtitle2" gutterBottom>
        {subtitle}
      </Typography>
      
      <FormControl component="fieldset" >
        <FormLabel component="legend">{data.question}</FormLabel>
        <RadioGroup
          row
          aria-label="likert-scale"
          name="row-radio-buttons-group"
          value={value}
          onChange={handleChange}
          disabled={data.type !== "selection"}
          sx={{display: "flex", flexDirection:"row", alignContent: "center", justifyContent: "center"}}>
          {Object.entries(data.options).map(([key, option]) => (
            Object.entries(option).map(([label, val]) => (
              <FormControlLabel key={val} value={val} control={<Radio />} label={label} />
            ))
          ))}
        </RadioGroup>
      </FormControl>
      {content.advices && <AdviceComponent advices={content.advices} onChangeAdvice={onChangeAdvice} id={id} />}

    </Box>
  );
};

export default LikertScaleComponent;