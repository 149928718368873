import { Line, Pie, Bar } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import 'chart.js/auto'
import { Chart } from 'react-chartjs-2';
import AdviceComponent from './AdviceComponent';
import { prepareChartData } from '../../../../utils/functions/prepareChartData';


const ChartComponent = ({ type, item, onChangeAdvice, id }) => {
  const { content } = item;
  const { subtitle, data } = content;

  let ChartType;
  if (type === 'line_chart') {
    ChartType = Line;
  } else if (type === 'bar_chart') {
    ChartType = Bar;
  } else {
    ChartType = Pie;
  }

  let chartSize = "100%";
  if (ChartType === Pie) { chartSize = "70%" }

  const chartData = prepareChartData(data, type);


  return (
    <Box sx={{ padding: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography variant="subtitle2" gutterBottom>
        {subtitle}
      </Typography>
      <Box sx={{ width: chartSize, display: "flex", justifyContent: "center", alignContent: "flex-end", maxHeight:"200px"}}>
        <ChartType data={chartData} options={chartData.options} />
      </Box>
      {content.advices && <AdviceComponent advices={content.advices} onChangeAdvice={onChangeAdvice} id={id} />}

    </Box>
  );
};

export default ChartComponent;