/**
 * This is the item as displayed within the module
 * @param {*} item
 * @returns
 * {
                    "type": "QuestionnaireItem",
                    "context": {},
                    "id": 9,
                    "evaluation": null,
                    "name": "Data Analysis Questionnaire",
                    "description": "",
                    "active": false,
                    "status": "NOT_STARTED",
                    "updated_at": "2024-04-07T15:20:05.100846+02:00",
                    "order": 1,
                    "company_id": null,
                    "questionnaire_identifier": "DA_QUESTIONNAIRE",
                    "completion_method": "AUTO",
                    "group_completion_requirement": "ALL_MEMBERS"
                    
                    "permissions": []
                },
 */

import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";


function Item({ item }) {
    const navigate = useNavigate();
    const { serviceId } = useParams();
    // console.log(item);
    const { name, status, evaluation, user_permissions, actions, context } = item;

    let buttonText;
    if ('evaluation' in item) {
        buttonText = evaluation !== null ? "See Evaluation" : "Evaluate";
    } else {
        buttonText = status !== "COMPLETED" ? "Complete" : "View";
    }

    let statusText;
    

    const canBeCompleted = user_permissions.includes("add_questionnaire");
    const startItemHandler = () => {
        // console.log("Starting item", item);
        navigate(`/app/service/${serviceId}/item/${item.id}`)
    }

    // console.log(context);

    return (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "baseline", width: "100%", justifyContent: "space-between" }}>

            <Typography variant="body1">{name}</Typography>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 2, width:"45%" }}>

                <Typography variant="body2"> Completion: {context.num_completed}/{context.num_attendees} </Typography>
                {actions?.start_item && <Button variant="contained" onClick={startItemHandler}>Start</Button>}
            </Box>
        </Box>
    )
}

export default Item;