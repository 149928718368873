import { createTheme, responsiveFontSizes } from "@mui/material";
import "@fontsource-variable/jost"


let theme = createTheme({  
    palette: {
    primary: {
      main: "#c00000",
    },
    secondary: {
      main: "#661333",
    },
    tertiary: {
      main: "#e30615",
      light: "#E83743",
      dark: "#9E040E",
      contrastText: "#fff",
    },
    greys: {
      main: "#7F7F7F",
      light: "#D8D8D8",
      dark: "#505050",
      contrastText: "#fff",
      ultraLight: "#F7F7F8"
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: "'Jost Variable', Roboto, Arial, sans-serif",
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 500,

  }
})

theme = responsiveFontSizes(theme)

export default theme