import React from "react";

const HorizontalArrow = ({}) => {
  const height = 5;
  const arrowSize = height * 4;

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-row w-full h-full justify-center items-center">
        <div className="h-[6px] w-[94%] bg-[#C00000]">
          <div className="w-full h-[4px] my-[1px]"></div>
        </div>
        <div
          className="w-0 h-2 border-t-[2.5rem] border-b-[2.5rem] border-transparent border-l-[5rem] border-l-[#C00000]"
          style={{ borderWidth: `${arrowSize / 2}px ${arrowSize}px` }}
        ></div>
      </div>
      <div className="text-[#C00000] font-bold">
        Receptiveness to DEI
      </div>
    </div>
  );
};

export default HorizontalArrow;
