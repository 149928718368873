
import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getQuestionnaireItem, submitQuestion, submitQuestionnaire } from "../../../../utils/apiCalls/axios-service";
import { useEffect, useState } from "react";
import TypeParser from "./TypeParser";
import useInterval from "../../../../utils/hooks/useInterval";
import { useNavigate, useParams } from "react-router-dom";

function QuestionnaireItem({ item }) {

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [questionnaireData, setQuestionnaireData] = useState(null);
    const [answers, setAnswers] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const [lastQuestionId, setLastQuestionId] = useState(null);
    const { serviceId } = useParams();


    const { data: questionnaireDataQuery, isLoading, error, refetch: refetchQuestionnaire } = useQuery({
        queryKey: ["QuestionnaireItem", item.id],
        queryFn: () => getQuestionnaireItem(item.id),
        staleTime: 0
    });

    useEffect(() => {
        if (questionnaireDataQuery) {
            setQuestionnaireData(questionnaireDataQuery);
        }
    }, [questionnaireDataQuery])


    const { mutate, isLoading: isSubmitting, isError, error: submissionError } = useMutation({
        mutationFn: submitQuestion,
        onError: (error) => {
        },
        onSuccess: (data) => {
            refetchQuestionnaire();
        }
    });


    const handleOnAnswerChange = (questionId, answer) => {

        const currentContent = questionnaireData?.questionnaire?.content?.sequence[currentStep];
        let questionData = null;
        for (const key in currentContent) {
            if (currentContent.hasOwnProperty(key)) {
                if (currentContent[key].data.question_id === questionId) {
                    questionData = currentContent[key];
                    break;
                }
            }
        }
        let mode = null;

        if (questionData && questionData.data.answer_obj.answer_id === null) {
            mode = 'post';
        } else {
            mode = 'put';
        }

        let formattedAnswer = { ...answer, questionnaire: questionnaireData.id, last_question_id: lastQuestionId };
        mutate({ answer: formattedAnswer, mode: mode, answer_id: questionData?.data?.answer_obj?.answer_id });
    };


    const { mutate: mutateSubmitQuestionnaire } = useMutation({
        mutationFn: submitQuestionnaire,
        onError: (error) => {
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(["Service", serviceId], { refetchActive: true });
            navigate("../../Overview");
        }

    })

    const allQuestionsAnswered = (sequences, deep) => {
        if (deep) {
            for (const sequenceKey in sequences) {
                if (sequences.hasOwnProperty(sequenceKey)) {
                    const sequence = sequences[sequenceKey];
                    for (const questionKey in sequence) {
                        if (sequence.hasOwnProperty(questionKey)) {
                            
                            if(sequence[questionKey].type !== 'question') continue;

                            const question = sequence[questionKey].data;
                            console.log("Question:", question); 
                            if (!question.answer_obj || question.answer_obj.answer_id === null) {
                                return false;
                            }
                        }
                    }
                }
            }
            return true;
        } else {
            for (const questionKey in sequences) {
                if (sequences.hasOwnProperty(questionKey)) {
                    if(sequences[questionKey].type !== 'question') continue;
                    const question = sequences[questionKey];
                    if (!question.data.answer_obj || question.data.answer_obj.answer_id === null) {
                        return false;
                    }
                }
            }
            return true;
        }
    };


    // const currentStepQuestionsAnswered = () => {
    //     console.log();
    //     const currentContent = questionnaireData?.questionnaire?.content?.sequence[currentStep];
    //     if (!currentContent) return true;
    //     console.log("currentContent", currentContent)

    //     return Object.entries(currentContent).every(([key, item]) => {
    //         if (item.type !== 'question') {
    //             return true
    //         }
    //         if (item.data?.answer_obj?.answer !== null || undefined) {
    //             return true;

    //         } else return true

    //     })
    // }

    async function handleSubmit() {
        const sequences = questionnaireData.questionnaire.content.sequence;

        if (allQuestionsAnswered(sequences, true)) {
            mutateSubmitQuestionnaire({ questionnaireID: questionnaireData.id });
        } else {
            alert("Please answer all questions before submitting.");
        }
    }

    if (isLoading || !questionnaireData) {
        return <Skeleton variant="rectangular" width={"100%"} height={"100%"} />;
    }

    if (error) {
        console.log("Error")
        return <div>Error: {error.message}</div>;
    }

    const currentContent = questionnaireData?.questionnaire?.content?.sequence[currentStep]
    const totalSteps = Object.keys(questionnaireData?.questionnaire?.content?.sequence).length

    return (
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2, pb: 2 }}>
            <Typography variant="h4" gutterBottom>{questionnaireData.questionnaire.meta.name}</Typography>
            <Stack spacing={2} display={"flex"} marginY={2}>
                {Object.entries(currentContent || {}).map(([key, { type, data }]) => (
                    <TypeParser key={key} type={type} data={data} answer={answers[data.question_id || null]} onAnswerChange={handleOnAnswerChange}></TypeParser>
                ))}
            </Stack>
            <Box sx={{ display: "flex", justifyContent: "space-around", flexDirection: "row", mb: 2 }}>
                {totalSteps > 1 && (
                    <Button onClick={() => {
                        setCurrentStep(current => current - 1)
                    }} disabled={currentStep === 1}>
                        Previous
                    </Button>
                )}

                {currentStep === totalSteps && (
                    <Button variant="contained" color="primary" onClick={handleSubmit}
                        disabled={!allQuestionsAnswered(questionnaireData.questionnaire.content.sequence, true)}>
                        Submit
                    </Button>
                )}

                {totalSteps > 1 && currentStep < totalSteps && (
                    <Button onClick={() => {
                        setCurrentStep(current => current + 1)
                    }}
                        disabled={!allQuestionsAnswered(questionnaireData.questionnaire.content.sequence[currentStep], false)}>
                        Next
                    </Button>
                )}

            </Box>
            <Button onClick={() => navigate("../../Overview")}>Exit</Button>
        </Box>
    );
}


export default QuestionnaireItem;