/**
 * @todo:
 * 1. Finish the status and deadline things
 * 2. Add the serviceType extraction from the data
 * 3. 
 */
import { Box, ButtonBase, Divider, Skeleton, Typography } from "@mui/material"
import ServiceBanner from "./ServiceBanner"
import { useNavigate } from "react-router-dom"
import { serviceStateActions } from "../../utils/store/serviceState-slice"
import { useDispatch } from "react-redux"
import { useQuery } from "@tanstack/react-query"
import { getServices } from "../../utils/apiCalls/axios-auth"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader"

const ServiceCard = ({index, id, participants = 15, status = "open", deadline = "3 days", serviceType = "serviceDEI" }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const authHeader = useAuthHeader()
    // const token = authHeader.split(' ')[1]

    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["Services", { id }],
        queryFn: () => getServices(id), 
        staleTime:0,
    })
    if (isLoading) { return <Skeleton variant="rectangular" width={272} height={304} /> }
    // Still need to add the serviceType to the serviceInfo
    const serviceInfo = { title: data.name, company: data.group.department.company.name, department: data.group.department.name, group: data.group.name, description: data.description, modules: data.modules }

    const handleClick = (e) => {
        e.preventDefault()
        // dispatch(serviceStateActions.activateService({ id, title: serviceInfo.title, company: serviceInfo.company, department: serviceInfo.department, serviceType, modules: serviceInfo.modules}))
        // dispatch(serviceStateActions.printState())
        // console.log(data)
        navigate(`/app/service/${id}/overview/`)
    }


    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: 2,
            maxWidth: 320,
            height: 304,
            width: "100%"
        }}>
            <ButtonBase
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    // pr: 2,
                    // pl: 2,
                    pb: 2,
                    pt:1, 
                    alignItems: "flex-start",
                }}
                onClick={handleClick}
            >
                <ServiceBanner
                    title={serviceInfo.title}
                    company={serviceInfo.company}
                    department={serviceInfo.department}
                    usage="serviceCard"
                    serviceType={serviceType}
                    logoUrl={serviceInfo.logo_url}
                    index={index} />

                <Box sx={{ display: "flex", flexDirection: "column", px: 2, height: "100%" }}>
                    <Divider sx={{ width: '100%', mt: 1, mb:2 }} />
                    <Box sx={{ display: "flex" }}>
                        <Typography variant="body1">{serviceInfo.description}</Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexGrow: 1 }} />
                    <Box sx={{ display: "flex-end", flexDirection: "column", gap: 2, width: "100%" }}>
                        {/* <Box sx={{ display: "flex", flexDirection: "row " }}>
                        <Typography variant="body2">Participants: </Typography>

                        <Typography variant="body2">{participants}</Typography>

                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row " }}>
                        <Typography variant="body2">Deadline: {deadline}</Typography>
                    </Box> */}
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography variant="body2">Status: </Typography>
                            <Box sx={{ display: "flex", flexGrow: 1 }} />
                            <FiberManualRecordIcon sx={{ color: "green" }} />

                        </Box>
                    </Box>
                </Box>
            </ButtonBase>
        </Box>
    )
}

export default ServiceCard